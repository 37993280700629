import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SharedFunctionsService} from "../../../shared";

const API_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class LicensesService {


  constructor(private http: HttpClient,
              private sharedFunctionsService:SharedFunctionsService) {
  }
  getlicenses(): Observable<any[]> {
    const headers = this.sharedFunctionsService.setHeaders('Skip-Loader')
    return this.http.get<any[]>(`${API_URL}/apifew/all-licenses`,{headers});
  }
}



