import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MediaType, Partner, PartnerService, UserService} from "../../../index";
import {AdvertiserService, FormValidationService, SwalNotificationsService} from "../../../../../shared";
import {Subscription} from "rxjs/internal/Subscription";
import {AuthService, UserType} from "../../../../auth";
import {Permission} from "../../../role-management/models/Permission.model";
import {ModuleAccess} from "../../../role-management/enums/ModuleAccess";
import {AccessLevel} from "../../../role-management/enums/AccessLevel";
import {RoleManagementService} from "../../../role-management/services/role-management.service";
import {DynamicRole} from "../../../role-management/models/DynamicRole.model";
import {map} from "rxjs";

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrl: './user-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})


export class UserModalComponent implements OnInit, OnDestroy {
  advertisersDvSpinner = false;
  advertisersAmzSpinner = false;
  advertisersFbSpinner = false;
  advertisersXandrSpinner = false;
  advertisersTikTokSpinner = false;
  partnersSpinner = false;
  rolesSpinner = false;
  isCollapsed1 = false;
  isCollapsed2 = false;
  isCollapsed3 = false;
  isCollapsed4 = false;
  allRoles: DynamicRole[] = [];
  allPartners: Partner[] = [];
  licenses: any[] = [];
  subscriptions: Subscription[] = [];
  SaveBtnLabel: string = 'Save';
  modalLabel: string = 'New User';
  userForm !: FormGroup;
  user: any;
  advertisersDsp: any[] = [];
  advertisersDv: any[] = [];
  advertisersFbAds: any[] = [];
  advertisersXandr: any[] = [];
  advertisersTiktok: any[] = [];
  protected readonly ModuleAccess = ModuleAccess;
  protected readonly AccessLevel = AccessLevel;
  isCheckedAllDvAdvertisers: boolean;
  dvAdvertisersIsEmpty: boolean = true;
  isCheckedAllDspAdvertisers: boolean;
  isCheckedAllFbAdvertisers: boolean;
  dspAdvertisersIsEmpty: boolean = true;
  fbAdvertisersIsEmpty: boolean = true;
  isCheckedAllXandrAdvertisers: boolean;
  isCheckedAllTikTokAdvertisers: boolean;
  xandrAdvertisersIsEmpty: boolean = true;
  tiktokAdvertisersIsEmpty: boolean = true;
  defaultMaxElementToShowInSelect = 4;
  maxElementToShowInDvAdvertisersSelect = this.defaultMaxElementToShowInSelect;
  maxElementToShowInDspAdvertisersSelect = this.defaultMaxElementToShowInSelect;
  maxElementToShowInFbAdvertisersSelect = this.defaultMaxElementToShowInSelect;
  maxElementToShowInXandrAdvertisersSelect = this.defaultMaxElementToShowInSelect;
  maxElementToShowInTikTokAdvertisersSelect = this.defaultMaxElementToShowInSelect;
  isSupAdmin : boolean = false;
  isPartnerAdmin : boolean = false;
  allowedDomain : string = '';
  public hasDV: boolean = false;
  public hasDSP: boolean = false;
  public hasFB: boolean = false;
  public hasXDR: boolean = false;
  public hasTKTK: boolean = false;
  protected readonly MediaType = MediaType;

  /**
   * @param formBuilder
   * @param userService
   * @param partnerService
   * @param roleManagementService
   * @param cdRef
   * @param dialogRef
   * @param userToEdit
   * @param formValidation
   * @param swalNotifs
   * @param advertiserService
   * @param authService
   */
  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private partnerService: PartnerService,
              private roleManagementService: RoleManagementService,
              private cdRef: ChangeDetectorRef,
              private dialogRef: MatDialogRef<UserModalComponent>,
              @Inject(MAT_DIALOG_DATA) public userToEdit: any,
              private formValidation: FormValidationService,
              private swalNotifs: SwalNotificationsService,
              private advertiserService: AdvertiserService,
              private authService: AuthService
  ) {
      this.subscribeToCurrentUser();
  }

  private subscribeToCurrentUser() {
    /** Subscribe to current user */
    const currentUserSub = this.authService.currentUser$.subscribe({
      next: (user:UserType) => {

        if(!user) return

        const features = user.apifewResponse?.license_features || [];

        if (features) {
          const mediaConnectorFeature = features.find((lf: any) => lf.name === 'media_connector_bk');
          const mediaConnectorAk = features.find((lf: any) => lf.name === 'media_connector_ad');

          this.hasDV = this.authService.hasFeature(mediaConnectorFeature?.value, ['all', 'dv360']) ||
                       this.authService.hasFeature(mediaConnectorAk?.value, ['all', 'dv360']);
          this.hasDSP = this.authService.hasFeature(mediaConnectorFeature?.value, ['all', 'amazon_dsp']);
          this.hasFB = this.authService.hasFeature(mediaConnectorFeature?.value, ['all', 'fb_ads']);
          this.hasXDR = this.authService.hasFeature(mediaConnectorFeature?.value, ['all', 'xandr']);
          this.hasTKTK = this.authService.hasFeature(mediaConnectorFeature?.value, ['all', 'tiktok_ads']);

        }
      }
    });
    /** Add subscription to unsubscribe array for cleanup */
    this.subscriptions.push(currentUserSub);
  }


  ngOnInit(): void {
    this.initializeUserDomain();
    this.checkUserRoles();
    this.formInit();
    this.disableDialogClose()
  }

  hasPermission(permissionsWithAccess: Permission[]): boolean {
    return this.authService.checkIfUserHasExpectedPermissions(permissionsWithAccess);
  }

  hasAllPermission(permissionsWithAccess: Permission[]): boolean {
    return this.authService.checkIfUserHasAllTheExpectedPermissions(permissionsWithAccess);
  }


  private checkUserRoles(): void {
    this.isSupAdmin = this.hasAllPermission(this.authService.getPermissionsForRole(ModuleAccess.ROLE_MANAGEMENT));

    // Only check for partner admin if the user is not a super admin
    this.isPartnerAdmin = !this.isSupAdmin && this.hasPermission(([{
      moduleAccess: ModuleAccess.USER_MANAGEMENT,
      accessLevel: AccessLevel.CREATE
    }]));
  }

  private disableDialogClose(): void {
    setTimeout(() => {
      this.dialogRef.disableClose = true;
    }, 0);
  }

  private initializeUserDomain(): void {
    const email = this.authService.currentUserValue?.email;
    if (email) {
      this.allowedDomain = this.getDomainFromEmail(email);
    }
  }

  private getDomainFromEmail(email: string): string {
    const domain = email.split('@')[1];
    return domain ? domain : '';
  }

  formInit() {
    this.userForm = this.formBuilder.group(
      {
        licenseId: [this.isPartnerAdmin ? this.authService?.currentUserValue?.licenseId : '' , Validators.required],
        id: '',
        firstName: ['', Validators.required],
        lastName: [''],
        email: ['', [
          Validators.required,
          Validators.email,
          Validators.pattern(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/),
          restrictedDomainValidator(this.allowedDomain,this.isSupAdmin)]
        ],
        status: ['ACTIVATED', Validators.required],
        company: [''],
        dvAdvertisers: [[]],
        amzAdvertisers: [[]],
        xandrAdvertisers: [[]],
        tiktokAdvertisers: [[]],
        fbAdsAdvertisers: [[]],
        notification: [false],
        roles: ['', Validators.required],
        partners: ['', Validators.required],
      }
    )
    if (this.userToEdit?.roles) {
      this.modalLabel = 'Edit User';
      this.SaveBtnLabel = 'Edit';
      this.userForm.patchValue(this.userToEdit);
    }
    this.licenses = this.userToEdit.licenses;

    //if user doesn't have permission to update license, disable the license field
    if (!this.hasPermission([{
      moduleAccess: ModuleAccess.LICENSE_MANAGEMENT,
      accessLevel: AccessLevel.UPDATE
    }])) {
      this.userForm.controls.licenseId.disable();
    }


  }

  ngSelectCompareWith(role1: DynamicRole, role2: DynamicRole) {
    return role1 && role2 && role1.id === role2.id;
  }

  resetAndCloseForm() {
    this.userForm.reset();
    this.dialogRef.close(this.user);
  }

  onSubmit() {
    if (this.userForm.invalid) {
      this.formValidation.validateAllFormFields(this.userForm);
      return;
    }
    if (this.userForm.valid) {
      // Check specifically for user data properties
      if (this.userToEdit?.id) {
        this.updateUser();
      } else {
        this.saveUser();
      }
    }
  }

  validateEmail() {
    return this.userForm.controls.email.valid || this.userForm.controls.email.untouched;
  }

  emailHasError(errorType: string): boolean {
    const emailControl = this.userForm.controls.email;
    return emailControl.hasError(errorType) && emailControl.touched;
  }

  validateFirstName() {
    return this.userForm.controls.firstName.valid || this.userForm.controls.firstName.untouched;
  }

  validateRoles() {
    return this.userForm.controls.roles.valid || this.userForm.controls.roles.untouched;
  }

  validatePartners() {
    return this.userForm.controls.partners.valid || this.userForm.controls.partners.untouched;
  }

  compareFn(item1: any, item2: any): boolean {
    return item1.id === item2.id;
  }

  getPartners() {
    this.partnersSpinner = !this.partnersSpinner;
    const rolesSubscription = this.partnerService
      .getPartners('user')
      .pipe(
        map(data => data.filter(partner => partner.isEnabled === true))
      )
      .subscribe({
        next: (filteredData) => {
            this.allPartners = [...filteredData];
            this.partnersSpinner = !this.partnersSpinner;
            this.cdRef.detectChanges();
        },
        error: (error) => {
          this.partnersSpinner = !this.partnersSpinner;
          this.swalNotifs.errorNotification("Error fetching partners,Please try again.")
        }
      });
    this.subscriptions.push(rolesSubscription);
  }

  getRoles() {
    this.rolesSpinner = !this.rolesSpinner;
    const rolesSubscription = this.roleManagementService.getRoles('user').subscribe(
      {
        next: (roles) => {
          this.allRoles = [...roles]
            .filter((role:any) => {
               if(this.isSupAdmin) return true ;

               if(this.isPartnerAdmin) {
                 const rolePermissions = role.permissions || [];
                 const supAdminPermissions = this.authService.getPermissionsForRole(ModuleAccess.ROLE_MANAGEMENT) || []

                 return this.excludeRoleSupAdmin(rolePermissions,supAdminPermissions)
               }
            });
          this.rolesSpinner = !this.rolesSpinner;
          this.cdRef.detectChanges();
        },
        error: () => {
          this.rolesSpinner = !this.rolesSpinner;
          this.swalNotifs.errorNotification("Error fetching roles,Please try again.")
        }
      })
    this.subscriptions.push(rolesSubscription)
  }

  private excludeRoleSupAdmin(rolePermissions: Permission[], supAdminPermissions: Permission[]): boolean {
    return !supAdminPermissions
      .every((supPer:Permission) =>
        rolePermissions
          .some((rolePer : Permission) =>
            supPer.moduleAccess === rolePer.moduleAccess &&
            supPer.accessLevel === rolePer.accessLevel
          )
      )
  }

  onOpenAdvertisersSelect(mediaType: MediaType) {
    switch (mediaType) {
      case MediaType.FACEBOOK_ADS:
        if (!this.advertisersFbSpinner) {
          this.getAdvertisersByPartners(mediaType);
        }
        break;
      case MediaType.AMAZON_DSP:
        if (!this.advertisersAmzSpinner) {
          this.getAdvertisersByPartners(mediaType);
        }
        break;
      case MediaType.DISPLAY_VIDEO:
        if (!this.advertisersDvSpinner) {
          this.getAdvertisersByPartners(mediaType);
        }
        break;
      case MediaType.XANDR:
        if (!this.advertisersXandrSpinner) {
          this.getAdvertisersByPartners(mediaType);
        }
        break;
      case MediaType.TIKTOK:
        if (!this.advertisersTikTokSpinner) {
          this.getAdvertisersByPartners(mediaType);
        }
        break;
    }
  }

  /**
   * Retrieves advertisers based on partners associated with the user being edited.
   */
  getAdvertisersByPartners(mediaType: MediaType) {
    // Extract partner IDs if any exist
    const partners = this.userForm.controls.partners.value;

    if (!partners?.length) {
      return; // Exit early if there are no partners
    }

    const ids: number[] = partners.map((partner: any) => partner.id);

    this.toggleAdvertiserLoadingSpinner(mediaType);

    const getAdvertisersByPartnersSub = this.advertiserService.getAdvertisersByPartners(ids, mediaType).subscribe({
      next: (advertisers: any) => {

        switch (mediaType) {
          case MediaType.DISPLAY_VIDEO:
            this.advertisersDv = advertisers.dvAdvertisers;
            break;
          case MediaType.AMAZON_DSP:
            this.advertisersDsp = advertisers.amzAdvertisers;
            break;
          case MediaType.FACEBOOK_ADS:
            this.advertisersFbAds = advertisers.fbAdsAdvertisers;
            break;
          case MediaType.XANDR:
            this.advertisersXandr = advertisers.xandrAdvertisers;
            break;
          case MediaType.TIKTOK:
            this.advertisersTiktok = advertisers.tiktokAdvertisers;
            break;
        }
        this.cdRef.detectChanges();

        this.toggleAdvertiserLoadingSpinner(mediaType);
      },
      error: (error) => {
        console.error('Error fetching advertisers:', error);

        // Disable the spinner in case of an error
        this.toggleAdvertiserLoadingSpinner(mediaType);
      }
    });

    this.subscriptions.push(getAdvertisersByPartnersSub);
  }

  toggleAdvertiserLoadingSpinner(mediaType: MediaType) {
    switch (mediaType) {
      case MediaType.DISPLAY_VIDEO:
        this.advertisersDvSpinner = !this.advertisersDvSpinner;
        break;
      case MediaType.AMAZON_DSP:
        this.advertisersAmzSpinner = !this.advertisersAmzSpinner;
        break;
      case MediaType.FACEBOOK_ADS:
        this.advertisersFbSpinner = !this.advertisersFbSpinner;
        break;
      case MediaType.XANDR:
        this.advertisersXandrSpinner = !this.advertisersXandrSpinner;
        break;
      case MediaType.TIKTOK:
        this.advertisersTikTokSpinner = !this.advertisersTikTokSpinner;
        break;
    }
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: any) => subscription.unsubscribe());
  }

  updateUser() {
    if (!Array.isArray(this.userForm.controls.roles.value)) {
      this.userForm.setControl('roles', this.formBuilder.array([this.userForm.controls.roles]));
    }
    let licenceChanges: any[] = [] /*this.licenceChanges()*/;
    let removedPartners = this.partnerChanges();
    let advertisersChanges = this.advertisersChanges();
    const updateUserSubscription = this.userService.updateUser(this.userForm.getRawValue(), licenceChanges, removedPartners, advertisersChanges).subscribe(
      {
        next: (response) => {
          this.user = response;
          this.resetAndCloseForm();
          this.swalNotifs.successNotification("User updated successfully");
        },
        error: (error) => {
          this.swalNotifs.errorNotification(error.error)
        }
      })
    this.subscriptions.push(updateUserSubscription)
  }


  licenceChanges() {
    const features = [
      {feature: "budgetkeeper_dv360", key: "DISPLAY_VIDEO"},
      {feature: "budgetkeeper_amz", key: "AMAZON_DSP"},
      {feature: "budgetkeeper_fbads", key: "FACEBOOK_ADS"},
      {feature: "budgetkeeper_xandr", key: "XANDR"},
      {feature: "budgetkeeper_tiktok", key: "TIKTOK"}
    ];
    let changes: { action: string, feature: string }[] = [];

    // new licence
    const newLicenceId = this.userForm.getRawValue().licenseId;
    const newLicence = this.licenses.find(licence => licence.id === newLicenceId);
    // old licence
    const oldLicenceId = this.userToEdit.licenseId;
    const oldLicence = this.licenses.find(licence => licence.id === oldLicenceId);
    if (!oldLicence) {
      features.forEach(({feature, key}) => {
        const newHasFeature = newLicence.features.includes(feature);
        if (newHasFeature) {
          changes.push({action: 'activate', feature: key});
        }
      });
    } else {
      if (newLicence !== oldLicence) {
        features.forEach(({feature, key}) => {
          const newHasFeature = newLicence.features.includes(feature);
          const oldHasFeature = oldLicence.features.includes(feature);

          if (newHasFeature && !oldHasFeature) {
            changes.push({action: 'activate', feature: key});
          } else if (!newHasFeature && oldHasFeature) {
            changes.push({action: 'desactivate', feature: key});
          }
        });
      }
    }
    return changes;
  }


  partnerChanges() {
    let removedPartners: { partnerId: number }[] = [];
    const newPartners = this.userForm.getRawValue().partners;
    const oldPartners = this.userToEdit.partners;
    if (Array.isArray(newPartners) && Array.isArray(oldPartners) && newPartners !== oldPartners) {
      oldPartners.forEach((oldPartner: { id: any }) => {
        const newHasPartner = newPartners.find((partner: any) => partner.id === oldPartner.id);
        if (!newHasPartner) {
          removedPartners.push(oldPartner.id);
        }
      });
    }

    return removedPartners;
  }

  advertisersChanges() {
    const changes: { action: string; advertiser: any }[] = [];

    const processAdvertisers = (newAdvertisers: any[], oldAdvertisers: any[]) => {
      // Ensure both arrays are valid before processing
      if (Array.isArray(newAdvertisers) && Array.isArray(oldAdvertisers)) {
        oldAdvertisers.forEach((oldAdvertiser) => {
          if (!newAdvertisers.some((newAdvertiser) => newAdvertiser.id === oldAdvertiser.id)) {
            changes.push({ action: "delete", advertiser: oldAdvertiser });
          }
        });

        newAdvertisers.forEach((newAdvertiser) => {
          if (!oldAdvertisers.some((oldAdvertiser) => oldAdvertiser.id === newAdvertiser.id)) {
            changes.push({ action: "add", advertiser: newAdvertiser });
          }
        });
      }
    };

    // Process each type of advertisers
    const advertiserTypes = [
      { newAdvertisers: this.userForm.getRawValue().dvAdvertisers, oldAdvertisers: this.userToEdit.dvAdvertisers },
      { newAdvertisers: this.userForm.getRawValue().amzAdvertisers, oldAdvertisers: this.userToEdit.amzAdvertisers },
      { newAdvertisers: this.userForm.getRawValue().fbAdsAdvertisers, oldAdvertisers: this.userToEdit.fbAdsAdvertisers },
      { newAdvertisers: this.userForm.getRawValue().xandrAdvertisers, oldAdvertisers: this.userToEdit.xandrAdvertisers },
      { newAdvertisers: this.userForm.getRawValue().tiktokAdvertisers, oldAdvertisers: this.userToEdit.tiktokAdvertisers },
    ];

    advertiserTypes.forEach(({ newAdvertisers, oldAdvertisers }) => {
      processAdvertisers(newAdvertisers || [], oldAdvertisers || []);
    });

    return changes;
  }

  private saveUser() {
    if (!Array.isArray(this.userForm.controls.roles.value)) {
      this.userForm.setControl('roles', this.formBuilder.array([this.userForm.controls.roles]));
    }
    const saveUserSubscription = this.userService.PostUser(this.userForm.value).subscribe(
      {
        next: (response) => {
          this.user = response;
          this.resetAndCloseForm();
          this.swalNotifs.successNotification("User added successfully");
        },
        error: (error) => {
          this.swalNotifs.errorNotification(error.error)
        }
      })
    this.subscriptions.push(saveUserSubscription)
  }

  removePartner(partnerId: number) {
    /**
     * Dv 360
     */
    const dvAdvertisersSelected = this.userForm.controls.dvAdvertisers.value
    const filteredDvAdvertisers = dvAdvertisersSelected.filter((adv: any) => adv.partnerId !== partnerId);
    this.userForm.controls.dvAdvertisers.setValue(filteredDvAdvertisers);
    /**
     * Amazon dsp
     */
    const amzAdvertisersSelected = this.userForm.controls.amzAdvertisers.value
    const filteredAmzAdvertisers = amzAdvertisersSelected.filter((adv: any) => adv.partnerId !== partnerId);
    this.userForm.controls.amzAdvertisers.setValue(filteredAmzAdvertisers);

    /**
     * Facebook ads
     */
    const fbAdvertisersSelected = this.userForm.controls.fbAdsAdvertisers.value
    const filteredFbAdvertisers = fbAdvertisersSelected.filter((adv: any) => adv.partnerId !== partnerId);
    this.userForm.controls.fbAdsAdvertisers.setValue(filteredFbAdvertisers);

    /**
     * Xandr
     */
    const xandrAdvertisersSelected = this.userForm.controls.xandrAdvertisers.value
    const filteredXandrAdvertisers = xandrAdvertisersSelected.filter((adv: any) => adv.partnerId !== partnerId);
    this.userForm.controls.xandrAdvertisers.setValue(filteredXandrAdvertisers);

    /**
     * Tiktok
     */
    const tiktokAdvertisersSelected = this.userForm.controls.tiktokAdvertisers.value
    const filteredTiktokAdvertisers = tiktokAdvertisersSelected.filter((adv: any) => adv.partnerId !== partnerId);
    this.userForm.controls.tiktokAdvertisers.setValue(filteredTiktokAdvertisers);

  }

  clearAll(forSelect: string) {
    if (forSelect === "dvAdvertisers") {
      this.isCheckedAllDvAdvertisers = false;
    } else if (forSelect === "dspAdvertisers") {
      this.isCheckedAllDspAdvertisers = false;
    } else if ((forSelect === "fbAdsAdvertisers")) {
      this.isCheckedAllFbAdvertisers = false;
    } else if ((forSelect === "xandrAdvertisers")) {
      this.isCheckedAllXandrAdvertisers = false;
    } else if ((forSelect === "tiktokAdvertisers")) {
      this.isCheckedAllTikTokAdvertisers = false;
    }
  }

  isCheckedAllDv() {
    if (this.advertisersDv.length == 0) this.isCheckedAllDvAdvertisers = false;
    else
      this.isCheckedAllDvAdvertisers = this.userForm.controls.dvAdvertisers.value?.length == this.advertisersDv.length;
  }

  isCheckedAllDsp() {
    if (this.advertisersDsp.length == 0) this.isCheckedAllDspAdvertisers = false;
    else
      this.isCheckedAllDspAdvertisers = this.userForm.controls.amzAdvertisers.value?.length == this.advertisersDsp.length;
  }

  isCheckedAllFb() {
    if (this.advertisersFbAds.length == 0) this.isCheckedAllFbAdvertisers = false;
    else
      this.isCheckedAllFbAdvertisers = this.userForm.controls.fbAdsAdvertisers.value?.length == this.advertisersFbAds.length;
  }

  isCheckedAllXandr() {
    if (this.advertisersXandr.length == 0) this.isCheckedAllXandrAdvertisers = false;
    else
      this.isCheckedAllXandrAdvertisers = this.userForm.controls.xandrAdvertisers.value?.length == this.advertisersXandr.length;
  }

  isCheckedAllTikTok() {
    if (this.advertisersTiktok.length == 0) this.isCheckedAllTikTokAdvertisers = false;
    else
      this.isCheckedAllTikTokAdvertisers = this.userForm.controls.tiktokAdvertisers.value?.length == this.advertisersTiktok.length;
  }

  selectionchange($event: any[], forSelect: string) {
    if (forSelect === "dvAdvertisers")
      this.dvAdvertisersIsEmpty = !$event.length;
    else if (forSelect === "dspAdvertisers")
      this.dspAdvertisersIsEmpty = !$event.length;
    else if (forSelect === "fbAdsAdvertisers")
      this.fbAdvertisersIsEmpty = !$event.length;
    else if (forSelect === "xandrAdvertisers")
      this.xandrAdvertisersIsEmpty = !$event.length;
    else if (forSelect === "tiktokAdvertisers")
      this.tiktokAdvertisersIsEmpty = !$event.length;
  }

  toggleSelectAllDv($event: any) {
    if ($event.target.checked) {
      this.userForm.controls.dvAdvertisers.setValue(this.advertisersDv)
      this.dvAdvertisersIsEmpty = false
      this.isCheckedAllDvAdvertisers = true
    } else {
      this.userForm.controls.dvAdvertisers.reset()
      this.dvAdvertisersIsEmpty = true
      this.isCheckedAllDvAdvertisers = false
    }

  }

  toggleSelectAllDsp($event: any) {
    if ($event.target.checked) {
      this.userForm.controls.amzAdvertisers.setValue(this.advertisersDsp)
      this.dspAdvertisersIsEmpty = false
      this.isCheckedAllDspAdvertisers = true
    } else {
      this.userForm.controls.amzAdvertisers.reset()
      this.dspAdvertisersIsEmpty = true
      this.isCheckedAllDspAdvertisers = false
    }
  }

  toggleSelectAllFb($event: any) {
    if ($event.target.checked) {
      this.userForm.controls.fbAdsAdvertisers.setValue(this.advertisersFbAds)
      this.fbAdvertisersIsEmpty = false
      this.isCheckedAllFbAdvertisers = true
    } else {
      this.userForm.controls.fbAdsAdvertisers.reset()
      this.fbAdvertisersIsEmpty = true
      this.isCheckedAllFbAdvertisers = false
    }
  }

  toggleSelectAllXandr($event: any) {
    if ($event.target.checked) {
      this.userForm.controls.xandrAdvertisers.setValue(this.advertisersXandr)
      this.xandrAdvertisersIsEmpty = false
      this.isCheckedAllXandrAdvertisers = true
    } else {
      this.userForm.controls.xandrAdvertisers.reset()
      this.xandrAdvertisersIsEmpty = true
      this.isCheckedAllXandrAdvertisers = false
    }
  }

  toggleSelectAllTikTok($event: any) {
    if ($event.target.checked) {
      this.userForm.controls.tiktokAdvertisers.setValue(this.advertisersTiktok)
      this.tiktokAdvertisersIsEmpty = false
      this.isCheckedAllTikTokAdvertisers = true
    } else {
      this.userForm.controls.tiktokAdvertisers.reset()
      this.tiktokAdvertisersIsEmpty = true
      this.isCheckedAllTikTokAdvertisers = false
    }
  }

  getAllAdvertisersLabel(forSelect: string) {
    if (forSelect === "dvAdvertisers")
      return this.userForm.controls.dvAdvertisers.value.map((ad: any) => ad.advertiserName).join(',');
    else if (forSelect === "dspAdvertisers")
      return this.userForm.controls.amzAdvertisers.value.map((ad: any) => ad.advertiserName).join(',');
    else if (forSelect === "fbAdsAdvertisers")
      return this.userForm.controls.fbAdsAdvertisers.value.map((ad: any) => ad.advertiserName).join(',');
    else if (forSelect === "xandrAdvertisers")
      return this.userForm.controls.xandrAdvertisers.value.map((ad: any) => ad.advertiserName).join(',');
    else if (forSelect === "tiktokAdvertisers")
      return this.userForm.controls.tiktokAdvertisers.value.map((ad: any) => ad.advertiserName).join(',');
  }

  showAllSelected(forSelect: string) {
    if (forSelect === "dvAdvertisers")
      this.maxElementToShowInDvAdvertisersSelect = this.userForm.controls.dvAdvertisers.value.length
    else if (forSelect === "dspAdvertisers")
      this.maxElementToShowInDspAdvertisersSelect = this.userForm.controls.amzAdvertisers.value.length
    else if (forSelect === "fbAdsAdvertisers")
      this.maxElementToShowInFbAdvertisersSelect = this.userForm.controls.fbAdsAdvertisers.value.length
    else if (forSelect === "xandrAdvertisers")
      this.maxElementToShowInXandrAdvertisersSelect = this.userForm.controls.xandrAdvertisers.value.length
    else if (forSelect === "tiktokAdvertisers")
      this.maxElementToShowInTikTokAdvertisersSelect = this.userForm.controls.tiktokAdvertisers.value.length
  }

  hideSelected(forSelect: string) {
    if (forSelect === "dvAdvertisers")
      this.maxElementToShowInDvAdvertisersSelect = this.defaultMaxElementToShowInSelect
    else if (forSelect === "dspAdvertisers")
      this.maxElementToShowInDspAdvertisersSelect = this.defaultMaxElementToShowInSelect
    else if (forSelect === "fbAdsAdvertisers")
      this.maxElementToShowInFbAdvertisersSelect = this.defaultMaxElementToShowInSelect
    else if (forSelect === "xandrAdvertisers")
      this.maxElementToShowInXandrAdvertisersSelect = this.defaultMaxElementToShowInSelect
    else if (forSelect === "tiktokAdvertisers")
      this.maxElementToShowInTikTokAdvertisersSelect = this.defaultMaxElementToShowInSelect
  }

}

export function restrictedDomainValidator(allowedDomain: string, isSupAdmin: boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;
    // Skip the validation if the user is a super admin
    if (isSupAdmin) return null;

    const email = control.value as string;
    const domain = email.split('@')[1];

    if (domain && domain !== allowedDomain) {
      return {restrictedDomain: {value: email, allowedDomain}};
    }

    return null;
  };
}
