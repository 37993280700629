import {PartnerConfig} from "./partner-config.module";

export class Partner {
  id: number;
  name: string;
  address?: string;
  website?: string;
  email?: string;
  phoneNumber?: string;
  partnerConfig?: PartnerConfig;
  autoAssignment:boolean
  dvPartnerId : number;
  isEnabled?: boolean;
  createdDate?: Date;
  createdBy?: String;
  updatedBy?: String;
  usersNumber ?: number;
  linked?: boolean;
  logged?: boolean;
}

