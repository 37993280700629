import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Partner, PartnerService} from "../../../index";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormValidationService, SwalNotificationsService} from "../../../../../shared";
import {Subscription} from "rxjs/internal/Subscription";

@Component({
  selector: 'app-partner-modal',
  templateUrl: './partner-modal.component.html',
  styleUrl: './partner-modal.component.scss'
})
export class PartnerModalComponent implements OnInit, OnDestroy {
  partnerForm !: FormGroup;
  partner: Partner;
  SaveBtnLavel: string = 'Save';
  PartnerLabel: string = 'New Partner';
  public subscriptions: Subscription[] = []

  constructor(private dialogRef: MatDialogRef<PartnerModalComponent>,
              @Inject(MAT_DIALOG_DATA) public partnerToEdit: Partner,
              private formBuilder: FormBuilder,
              private partnerService: PartnerService,
              private formValidation: FormValidationService,
              private swalNotifs: SwalNotificationsService
  ) {
  }

  ngOnInit(): void {
    this.formInit();
    this.dialogRef.disableClose = true;
  }

  formInit() {
    this.partnerForm = this.formBuilder.group(
      {
        id: '',
        name: ['', Validators.required],
        address: '',
        website: '',
        email: ['', [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
        phoneNumber: ['', [Validators.pattern(/^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i)]],
        isEnabled: ['true', Validators.required],
        autoAssignment: [false, Validators.required],
      }
    )
    if (this.partnerToEdit) {
      this.SaveBtnLavel = 'Edit';
      this.PartnerLabel = 'Edit Partner';
      this.partnerForm.patchValue(this.partnerToEdit);
    }
  }

  resetAndCloseForm() {
    this.partnerForm.reset();
    this.dialogRef.close(this.partner);
  }

  submit() {
    if (this.partnerForm.invalid) {
      this.formValidation.validateAllFormFields(this.partnerForm);
      return; // If the form is invalid, stop processing
    }
    if (this.partnerToEdit) {
      this.updatePartner();
    } else {
      this.addPartner();
    }
  }

  validateEmail() {
    return this.partnerForm.controls.email.valid || this.partnerForm.controls.email.untouched;
  }

  validateName() {
    return this.partnerForm.controls.name.valid || this.partnerForm.controls.name.untouched;
  }

  validatePhone() {
    return this.partnerForm.controls.phoneNumber.valid || this.partnerForm.controls.phoneNumber.untouched;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  private addPartner() {
    const addSubscription = this.partnerService.postPartner(this.partnerForm.value).subscribe(
      {
        next: (response) => {
          this.partner = response;
          this.resetAndCloseForm();
          this.swalNotifs.successNotification('Partner added successfully')
        },
        error: (error) => {
          this.swalNotifs.errorNotification(error.error)
        }
      });
    this.subscriptions.push(addSubscription)
  }

  private updatePartner() {
    const updateSubscription = this.partnerService.updatePartner(this.partnerForm.value).subscribe(
      {
        next: (response) => {
          this.partner = response;
          this.swalNotifs.successNotification('Partner updated successfully')
          this.resetAndCloseForm();
        },
        error: (error) => {
          this.swalNotifs.errorNotification(error.error)
        }
      });
    this.subscriptions.push(updateSubscription)
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: any) => subscription.unsubscribe());
  }
}
