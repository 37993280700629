<div class="card p-0 container-fluid" *ngIf="show">
    <div aria-expanded="true" class="card-header border-0 cursor-pointer" data-bs-toggle="collapse" role="button">
        <div class="card-title m-0 border-0">
            <h3 class="fw-bolder m-0">{{ modalLabel }}</h3>
        </div>
        <div class="card-toolbar">
            <i class="fa fa-close" style="font-size:24px" (click)="resetAndCloseForm()"></i>
        </div>
    </div>
    <div class="card-body border-top pt-0">

        <form class="form-container" [formGroup]="groupForm" (submit)="submit()">
            <div class="row mt-0 p-0">
                <div  class="form-group my-2 col-xl-12">
                    <label class="col-form-label required fw-bold fs-6">
                      {{mediasThatHasAccessTo.length>1 ? 'Select one or multiple media platforms':'Select a media platform'}}
                    </label>
                    <ul class="nav nav-pills nav-pills-custom flex-nowrap overflow-x-auto position-relative"
                        [ngStyle]="{'opacity': this.disableSelectMediaMenu ? '0.6' : '1'}">
                        <!--begin::Item-->
                        <li class="nav-item mb-3 me-3 me-lg-6" role="presentation" *ngIf="hasDV">
                            <!--begin::Link-->
                            <div class="checkbox-wrapper-16" [ngClass]="{'checkbox-hover-enabled': !disableSelectMediaMenu}">
                                <label class="checkbox-wrapper">
                                    <input type="checkbox" class="checkbox-input" [disabled]="disableSelectMediaMenu"
                                           [checked]="selectedPlatforms.includes(MediaType.DISPLAY_VIDEO)"
                                           (change)="togglePlatformSelection(MediaType.DISPLAY_VIDEO)"  />
                                    <span class="checkbox-tile">
                                        <span class="checkbox-icon mb-3">
                                        <img alt="DISPLAY_VIDEO" src="assets/media/ironDomeIcons/dv.png"
                                            class="h-25px w-25px app-sidebar-logo-default theme-light-show">
                                        </span>
                                        <span class="nav-text text-gray-800 fw-bold fs-6 lh-1">DV360</span>
                                     </span>
                                </label>
                            </div>
                            <!--end::Link-->
                        </li>
                        <!--end::Item-->
                      <!--begin::Item-->
                      <li class="nav-item mb-3 me-3 me-lg-6" role="presentation" *ngIf="hasDSP">
                        <!--begin::Link-->
                        <div class="checkbox-wrapper-16" [ngClass]="{'checkbox-hover-enabled': !disableSelectMediaMenu}">
                          <label class="checkbox-wrapper">
                            <input type="checkbox" class="checkbox-input" [disabled]="disableSelectMediaMenu"
                                   [checked]="selectedPlatforms.includes(MediaType.AMAZON_DSP)"
                                   (change)="togglePlatformSelection(MediaType.AMAZON_DSP)"  />
                            <span class="checkbox-tile">
                                        <span class="checkbox-icon mb-3">
                                        <img alt="AMAZON_DSP" src="assets/media/ironDomeIcons/amazon.png"
                                             class="h-25px w-25px app-sidebar-logo-default theme-light-show">
                                        </span>
                                        <span class="nav-text text-gray-800 fw-bold fs-6 lh-1">Amazon</span>
                                     </span>
                          </label>
                        </div>
                        <!--end::Link-->
                      </li>
                      <!--end::Item-->
                        <!--begin::Item-->
                        <li class="nav-item mb-3 me-3 me-lg-6" role="presentation" *ngIf="hasFB">
                            <!--begin::Link-->
                            <div class="checkbox-wrapper-16" [ngClass]="{'checkbox-hover-enabled': !disableSelectMediaMenu}">
                                <label class="checkbox-wrapper">
                                    <input type="checkbox" class="checkbox-input" [disabled]="disableSelectMediaMenu"
                                           [checked]="selectedPlatforms.includes(MediaType.FACEBOOK_ADS)"
                                           (change)="togglePlatformSelection(MediaType.FACEBOOK_ADS)"   />
                                    <span class="checkbox-tile">
                                        <span class="checkbox-icon mb-3">
                                        <img alt="DISPLAY_VIDEO" src="assets/media/ironDomeIcons/meta.jpeg"
                                             class="h-25px w-35px app-sidebar-logo-default theme-light-show">
                                        </span>
                                        <span class="nav-text text-gray-800 fw-bold fs-6 lh-1">Meta</span>
                                     </span>
                                </label>
                            </div>
                            <!--end::Link-->
                        </li>
                        <!--end::Item-->
                        <!--begin::Item-->
                        <li class="nav-item mb-3 me-3 me-lg-6" role="presentation" *ngIf="hasXDR">
                            <!--begin::Link-->
                            <div class="checkbox-wrapper-16" [ngClass]="{'checkbox-hover-enabled': !disableSelectMediaMenu}">
                                <label class="checkbox-wrapper">
                                    <input type="checkbox" class="checkbox-input" [disabled]="disableSelectMediaMenu"
                                           [checked]="selectedPlatforms.includes(MediaType.XANDR)"
                                           (change)="togglePlatformSelection(MediaType.XANDR)"  />
                                    <span class="checkbox-tile">
                                        <span class="checkbox-icon mb-2">
                                        <img alt="DISPLAY_VIDEO" src="assets/media/ironDomeIcons/xandr.png"
                                             class="h-35px w-35px app-sidebar-logo-default theme-light-show">
                                        </span>
                                        <span class="nav-text text-gray-800 fw-bold fs-6 lh-1">Xandr</span>
                                     </span>
                                </label>
                            </div>
                            <!--end::Link-->
                        </li>
                        <!--end::Item-->
                        <!--begin::Item-->
                        <li class="nav-item mb-3 me-3 me-lg-6" role="presentation" *ngIf="hasTKTK">
                            <!--begin::Link-->
                            <div class="checkbox-wrapper-16" [ngClass]="{'checkbox-hover-enabled': !disableSelectMediaMenu}">
                                <label class="checkbox-wrapper">
                                    <input type="checkbox" class="checkbox-input" [disabled]="disableSelectMediaMenu"
                                           [checked]="selectedPlatforms.includes(MediaType.TIKTOK)"
                                           (change)="togglePlatformSelection(MediaType.TIKTOK)" />
                                    <span class="checkbox-tile">
                                        <span class="checkbox-icon mb-2">
                                        <img alt="DISPLAY_VIDEO" src="assets/media/ironDomeIcons/tiktok.png"
                                             class="h-40px w-40px app-sidebar-logo-default theme-light-show">
                                        </span>
                                        <span class="nav-text text-gray-800 fw-bold fs-6 lh-1">TikTok</span>
                                     </span>
                                </label>
                            </div>
                            <!--end::Link-->
                        </li>
                        <!--end::Item-->
                    </ul>
                </div>
                <div class="col-xl-12 mb-3">
                    <label class="col-form-label fw-bold fs-6 required" for="GroupName">Name</label>
                    <input type="text" class="form-control form-control-lg form-control-solid"
                           formControlName="groupName" id="GroupName">

                    <div class="m-0 p-0 position-absolute" *ngIf="!validateName() && !groupForm.get('groupName')?.hasError('forbiddenName')">
                        <small class="text-danger">Name is required</small>
                    </div>
                    <div class="m-0 p-0 position-absolute" *ngIf="groupForm.get('groupName')?.hasError('forbiddenName')">
                        <small class="text-danger">"{{groupForm.get('groupName')?.errors?.forbiddenName?.value}}" is not allowed as a group name</small>
                    </div>
                </div>
                <div class="col-xl-12 mb-3">
                    <label class="col-form-label required fw-bold fs-6" for="Campaign">Advertisers</label>
                    <ng-select [items]="advertisers" class="custom" id="advertisers"
                               bindLabel="advertiserName"
                               groupBy="formattedMediaName"
                               [compareWith]="compareFnAdvertisers"
                               [multiple]="true"
                               [closeOnSelect]="false"
                               [searchable]="true"
                               [clearable]="false"
                               [readonly]="!selectedPlatforms.length"
                               (clear)="clearAll('advertisers',$event)"
                               [loading]="advertisersSpinner"
                               (change)="isCheckedAll('advertisers',$event)"
                               (open)="getAdvertisers('advertisers')"
                               (remove)="revomedAdvertisers($event.advertiserId)"
                               (add)="addAdvertiser($event)"
                               appendTo="body"
                               formControlName="advertisers">
                                <ng-template ng-header-tmp>
                                    <label>
                                        <input type="checkbox" class="form-check-input" [checked]="isCheckedAllAdvertisers"
                                            (change)="toggleSelectAll($event,'advertisers')">
                                        <span class="fw-bold ps-2 fs-6">
                                            All
                                        </span>
                                    </label>
                                </ng-template>
                    </ng-select>
                    <div class="m-0 p-0 position-absolute" *ngIf="!validateAdvertisers()">
                        <small class="text-danger">Advertisers list is required</small>
                    </div>
                </div>
                <div class="col-xl-12 mb-3">
                    <label class="col-form-label required fw-bold fs-6" for="Campaign">Campaigns</label>
                    <ng-select [items]="Campaigns" class="custom" id="Campaign"
                               bindLabel="campaignName"
                               groupBy="advertiserName"
                               [compareWith]="compareFnCampaigns"
                               [multiple]="true"
                               [searchable]="true"
                               [readonly]="campaignsSpinner || !groupForm.controls.advertisers.value"
                               [closeOnSelect]="false"
                               [loading]="campaignsSpinner"
                               (clear)="clearAll('campaigns',$event)"
                               (change)="isCheckedAll('campaigns')"
                               (open)="getCampaigns('campaigns')"
                               (remove)="revomedCampaigns($event)"
                               appendTo="body"
                               formControlName="campaigns">
                        <ng-template ng-option-tmp let-item="item">
                            {{ item.campaignName }}
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                            <span (click)="clear(item)"
                                  class="ng-value-label ng-star-inserted ng-value-icon left ng-star-inserted">&times;</span>
                            {{ item.campaignName }}
                        </ng-template>
                        <ng-template ng-header-tmp>
                            <label>
                                <input type="checkbox" class="form-check-input" [checked]="isCheckedAllCampaigns"
                                       (change)="toggleSelectAll($event,'campaigns')">
                                <span class="fw-bold ps-2 fs-6">
                  All
                </span>
                            </label>
                        </ng-template>
                    </ng-select>

                    <div class="m-0 p-0 position-absolute" *ngIf="!validateCampaigns()">
                        <small class="text-danger">Campaigns list is required</small>
                    </div>
                </div>
            </div>
            <div class="row mt-15 p-0 mt-0 float-end">
                <div class="col">
                    <button class="btn btn-secondary" (click)="resetAndCloseForm()">
                        Cancel
                    </button>
                </div>
                <div class="col">
                    <button class="btn btn-primary" [disabled]="campaignsSpinner" type="submit" style="width: 80px;" >
                        {{ SaveBtnLabel }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
