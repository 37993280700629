<div class="card p-0 container-fluid overflow-hidden">
  <div aria-expanded="true" class="card-header border-0 cursor-pointer" data-bs-toggle="collapse" role="button">
    <div class="card-title m-0 border-0">
      <h3 class="fw-bolder m-0"> {{PartnerLabel}}</h3>
    </div>
    <div class="card-toolbar">
      <i class="fa fa-close" style="font-size:24px" (click)="resetAndCloseForm()"></i>
    </div>
  </div>
  <div class="card-body border-top pt-0 overflow-auto">

    <form class="form-container" [formGroup]="partnerForm" (submit)="submit()">
      <div class="form-group">
        <label class="col-form-label required fw-bold fs-6" for="partner-name">Name</label>
        <input type="text" class="form-control form-control-lg form-control-solid "
               formControlName="name" id="partner-name">
        <div class="m-0 p-0" *ngIf="!validateName()">
          <small class="text-danger">Partner name is required</small>
        </div>
      </div>
      <div class="form-group">
        <label class="col-form-label fw-bold fs-6" for="partner-address">Address</label>
        <input class="form-control form-control-lg form-control-solid " formControlName="address"
               id="partner-address">
      </div>
      <div class="form-group">
        <label class="col-form-label fw-bold fs-6" for="partner-website">Website</label>
        <input type="text" class="form-control form-control-lg form-control-solid "
               formControlName="website" id="partner-website">
      </div>
      <div class="form-group">
        <label class="col-form-label required fw-bold fs-6" for="partner-name">Email</label>
        <input type="email" class="form-control form-control-lg form-control-solid "
               formControlName="email" id="partner-email">
        <div class="m-0 p-0" *ngIf="!validateEmail()">
          <small class="text-danger" *ngIf="partnerForm.controls.email.errors?.required">Email
            address is required</small>
          <small class="text-danger" *ngIf="partnerForm.controls.email.errors?.pattern">Email
            address should have a correct form</small>
        </div>
      </div>
      <div class="form-group">
        <label class="col-form-label fw-bold fs-6" for="partner-phone">Phone</label>
        <input type="text" class="form-control form-control-lg form-control-solid "
               formControlName="phoneNumber" id="partner-phone">
        <div class="m-0 p-0" *ngIf="!validatePhone()">
          <small class="text-danger" *ngIf="partnerForm.controls.phoneNumber.errors?.pattern">Phone number should have
            a correct form</small>
        </div>
      </div>
      <div class="form-group">
        <label class="col-form-label fw-bold fs-6" for="partner-status">Status</label>
        <select class="form-control form-control-lg form-control-solid form-select" formControlName="isEnabled"
                id="partner-status">
          <option value="true">Active</option>
          <option value="false">Disabled</option>
        </select>
      </div>
      <div class="d-flex flex-stack mt-6">
          <div class="me-5">
            <label class="fs-6 fw-bold">Automatic assignment ?</label>
            <div class="fs-7 fw-bold text-gray-500">
                <span> <i class="fas fa-exclamation-circle fs-7 me-1"></i></span>
                <span
                  class="text-muted fs-7 fw-bold">When enabled, this option automatically assigns this partner to you.
                </span>
            </div>
          </div>
          <label class="form-check form-switch form-check-custom form-check-solid">
            <input formControlName="autoAssignment" type="checkbox"  class="form-check-input ng-untouched ng-pristine ng-valid">
          </label>
      </div>
      <div class="row mt-0 p-0 mt-0 float-end mt-10">
        <div class="col mb-3">
          <button class="btn btn-secondary" (click)="resetAndCloseForm()">
            Cancel
          </button>
        </div>
        <div class="col">
          <button class="saveOrEditPartner btn btn-primary" type="submit">
            {{SaveBtnLavel}}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
