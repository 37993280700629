import { Injectable } from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {Partner} from "../../models/partner.model";
import {HttpClient} from "@angular/common/http";
import {DynamicRole} from "../models/DynamicRole.model";
import {Permission} from "../models/Permission.model";
import {UserModel} from "../../../auth";
import {SharedFunctionsService} from "../../../../shared";


const API_URL = `${environment.apiUrl}`;
@Injectable({
  providedIn: 'root'
})
export class RoleManagementService {

  constructor(private http:HttpClient,private sharedFunctionsService:SharedFunctionsService) { }

  getRoles(from?:string) {
    let headers
    if(from){
      headers = this.sharedFunctionsService.setHeaders('Skip-Loader')
    }
    return this.http.get<DynamicRole[]>(`${API_URL}/role/all`,{headers});
  }

  getPermissions() {
    return this.http.get<Permission[]>(`${API_URL}/permission/all`);
  }

  saveRole(role: DynamicRole) {
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    return this.http.post<DynamicRole>(`${API_URL}/role/save`, role,{headers});
  }

  updateRole(role: DynamicRole) {
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    return this.http.put<DynamicRole>(`${API_URL}/role/update`, role,{headers});
  }

  deleteRole(id: string | undefined) {
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    return this.http.delete<DynamicRole>(`${API_URL}/role/delete/${id}`,{headers});
  }

  getRoleById(id: string | undefined) {
    return this.http.get<DynamicRole>(`${API_URL}/role/get/${id}`);
  }

  getUsersByRole(id: string | undefined) {
    return this.http.get<UserModel[]>(`${API_URL}/users/role/${id}`);
  }
}
