import {Injectable} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {MediaType, Partner} from "../../index";
import {SharedFunctionsService} from "../../../../shared";

const API_URL = `${environment.apiUrl}/partner`;
const API_URL_CONFIG = `${environment.apiUrl}/partner-config`;

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  /**
   * @param http : HttpClient
   * @param sharedFunctionsService : SharedFunctionsService
   */
  constructor(private http: HttpClient,
              private sharedFunctionsService:SharedFunctionsService) {
  }

  getPartners(from?: string): Observable<Partner[]> {
    let headers
    if(from){
       headers = this.sharedFunctionsService.setHeaders('Skip-Loader')
    }
    return this.http.get<Partner[]>(`${API_URL}/all`,{headers});
  }

  postPartner(data: any) {
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    return this.http.post<any>(`${API_URL}/save`, data,{headers})
  }

  deletePartner(id: number): Observable<any> {
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    return this.http.delete<any>(`${API_URL}/delete?partnerId=${id}`,{headers});
  }

  updatePartner(data: any) {
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    return this.http.post<any>(`${API_URL}/update`, data,{headers})
  }

  getAmazonToken(data: any) {
    // @ts-ignore
    return this.http.post<any>(`${API_URL_CONFIG}/amz?partnerId=${data.partnerId}&amazonProfileId=${data.amazonProfileId}&authCode=${data.authCode}`);
  }

  getSaEmail(id: any) {
    return this.http.get<any>(`${API_URL_CONFIG}/sa-email?partnerId=${id}`);
  }

  savePartnerConfigMediaDv(data: any) {
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    // @ts-ignore
    return this.http.post<any>(`${API_URL_CONFIG}/dv360?partnerId=${data.partnerId}&dvPartnerId=${data.dvPartnerId}`, {},{headers})
  }

  savePartnerConfigMediaGtm(data: any) {
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    // @ts-ignore
    return this.http.post<any>(`${API_URL_CONFIG}/gtm?partnerId=${data.partnerId}&mainEmail=${data.main_email}`, {},{headers})
  }

  savePartnerConfigFacebookAds(data: any) {
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    // @ts-ignore
    return this.http.post<any>(`${API_URL_CONFIG}/fbads?partnerId=${data.partnerId}&token=${data.fbAdsToken}`, {},{headers})
  }

  savePartnerConfigXandr(data: any) {
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    return this.http.post<any>(`${API_URL_CONFIG}/xandr`, data,{headers});
  }

  savePartnerConfigTikTok(data: any) {
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    return this.http.post<any>(`${API_URL_CONFIG}/tiktok`, data,{headers});
  }

  getPartnerConfigMedia(id: any) {
    return this.http.get<any>(`${API_URL_CONFIG}/${id}`);
  }

  deletePartnerConfigMedia(configId: number, mediaType: MediaType) {
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    return this.http.delete<any>(`${API_URL_CONFIG}/${configId}?mediaType=${mediaType}`, {headers})
  }


}
