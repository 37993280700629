import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Role} from "../../index";
import {environment} from "../../../../../environments/environment";

const API_URL = `${environment.apiUrl}`;
@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private http: HttpClient) {
  }
  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(`${API_URL}/role/`);
  }
}
