import {ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import { TranslationService } from './modules/i18n';
// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { ThemeModeService } from './@core/_metronic/partials/layout/theme-mode-switcher/theme-mode.service';
import {OutsideClickService, UsetifulService} from "./shared";
import {AuthService, UserType} from "./modules/auth";

@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent  implements OnInit, OnDestroy {
  private readonly subscriptions: any[] = [];

  constructor(
    private readonly  translationService: TranslationService,
    private readonly modeService: ThemeModeService,
    private readonly outsideClick : OutsideClickService,
    private readonly authService: AuthService,
    private readonly usetifulService: UsetifulService,

  ) {
      this.subscribeToCurrentUser();
      // register translations
      this.registerTranslations();
  }

    private subscribeToCurrentUser() {
        /** Subscribe to current user */
        const currentUserSub = this.authService.currentUser$.subscribe({
            next: (user) => {
                if(user){
                    this.initializeUserTags(user);
                }

            }
        });

        /** Add subscription to unsubscribe array for cleanup */
        this.subscriptions.push(currentUserSub);
    }


  ngOnInit() {
    this.modeService.init();
  }
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    // Check if the clicked element or any of its ancestors have the class 'selectLogos'
      const targetElement = event.target as HTMLElement;
      if (targetElement && !targetElement.closest('.selectLogos')) {
          this.outsideClick.outsideClick$.next(true);
      }
  }

    private registerTranslations(): void {
        this.translationService.loadTranslations(
            enLang,
            chLang,
            esLang,
            jpLang,
            deLang,
            frLang
        );
    }
    private initializeUserTags(currentUser : UserType): void {
        if (currentUser) {
            this.usetifulService.setUserTags(
                currentUser.id,
                currentUser.firstName,
                currentUser.lastName,
                currentUser.roles[0].name!
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: any) => subscription.unsubscribe());
    }
}
