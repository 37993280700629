import { inject } from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import { AuthService } from "../auth.service";
import { SettingService } from "../../../projects/budgetkeeper/modules/settings";
import { map, catchError, of } from 'rxjs';

export const budgetAlertGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const settingService = inject(SettingService);
  const router = inject(Router);

  const currentUser = authService.currentUserValue;
  if (!currentUser) {
    router.navigate(['/error/401']); // Redirect if user is not authenticated
    return of(false);
  }

  const features = currentUser?.apifewResponse?.license_features || [];

  const budgetAlertConfig = features.find((feature: any) => feature.name === 'budget_alert_configuration');

  const isUnlimited = authService.parseFeatureValue(budgetAlertConfig?.value)?.en === 'Unlimited';

  if (isUnlimited) {
    return of(true); // The user can create unlimited alerts.
  }

  return settingService.getSettingsCount(currentUser.id).pipe(
    map((alertsCount: number) => {
      const isLimitedToSingleAlert = authService.parseFeatureValue(budgetAlertConfig?.value)?.en === '1';
      if (isLimitedToSingleAlert && alertsCount === 1) {
        router.navigate(['/error/401']);
        return false;
      }
      return true;
    }),
    catchError((error) => {
      console.error('Error fetching alerts count:', error);
      router.navigate(['/error/401']);
      return of(false);
    })
  );
};
