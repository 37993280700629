import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {environment} from "../../../../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {FormGroup} from "@angular/forms";
import {SharedFunctionsService} from "../../../../../../../shared";

const API_URL = `${environment.apiUrl}/setting`;

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  settingForm: FormGroup | null = null;
  currentStep$: BehaviorSubject<number> = new BehaviorSubject(1);

  public monthOptions = [
    {key: 'January', value: "-01-01"},
    {key: 'February', value: "-02-01"},
    {key: 'March', value: "-03-01"},
    {key: 'April', value: "-04-01"},
    {key: 'May', value: "-05-01"},
    {key: 'June', value: "-06-01"},
    {key: 'July', value: "-07-01"},
    {key: 'August', value: "-08-01"},
    {key: 'September', value: "-09-01"},
    {key: 'October', value: "-10-01"},
    {key: 'November', value: "-11-01"},
    {key: 'December', value: "-12-01"},
  ];

  constructor(private http: HttpClient,
              private sharedFunctionsService:SharedFunctionsService) {
  }

  resetAlertForm() {
    this.currentStep$.next(1)
    this.settingForm = null;
  }

  addSetting(newSetting: any): Observable<any> {
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    return this.http.post<any>(`${API_URL}/save`, newSetting,{headers});
  }

  updateSetting(settingToEdit: any): Observable<any> {
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    return this.http.post<any>(`${API_URL}/update`, settingToEdit,{headers});
  }

  getSettings(reqBody: any) {
    return this.http.post<any>(`${API_URL}/get-settings`, reqBody);
  }

  getSettingsNames(partnerId: number, userId: string) {
    return this.http.get<any>(`${API_URL}/setting-names?partnerId=${partnerId}&userId=${userId}`);
  }
  getSettingsCount(userId: string) {
    return this.http.get<any>(`${API_URL}/alerts-count?userId=${userId}`);
  }

  getSettingById(id: any) {
    return this.http.get<any>(`${API_URL}/get-setting?id=${id}`);
  }

  deleteSetting(bodyRequest: any) {
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    return this.http.post<any>(`${API_URL}/delete`, bodyRequest,{headers});
  }

}
