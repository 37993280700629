import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../../../../environments/environment";
import {group} from "@angular/animations";
import {SharedFunctionsService} from "../../../../../../../shared";

const API_URL = `${environment.apiUrl}/groups`;

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  /**
   * @param http
   * @param sharedFunctionsService
   */
  constructor(private http: HttpClient,
              private sharedFunctionsService:SharedFunctionsService) {
  }


  saveGroup(group: any) {
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    return this.http.post<any>(`${API_URL}/save`, group,{headers});
  }
  updateGroup(group: { campaigns: any; group: any }) {
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    return this.http.post<any>(`${API_URL}/update`, group,{headers});

  }
  getGroups(partnerId: number , userId : string) {
    return this.http.get<any[]>(`${API_URL}/get-groups?partnerId=${partnerId}&userId=${userId}`);
  }
  deleteGroup(groupId: string,partnerName:string,partnerId:number , userId : string) {
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    return this.http.delete<any>(`${API_URL}/delete?groupId=${groupId}&partnerName=${partnerName}&partnerId=${partnerId}&userId=${userId}`,{headers});

  }

}
