import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from "../auth.service";

@Injectable({
    providedIn: 'root'
})

export class MediaGuard {
    hasDV: boolean = false;
    hasDSP: boolean = false;
    hasFB: boolean = false;
    hasXDR: boolean = false;
    hasTKTK: boolean = false;

    constructor(private authService: AuthService, private router: Router) {
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        const currentUser = this.authService.currentUserValue;
        const component: string = route.data.component;

        const features = currentUser?.apifewResponse?.license_features || [];

        if (currentUser && features) {
            const mediaConnectorFeature = features.find((lf: any) => lf.name === 'media_connector_bk');

            this.hasDV = this.authService.hasFeature(mediaConnectorFeature?.value, ['all', 'dv360']);
            this.hasDSP = this.authService.hasFeature(mediaConnectorFeature?.value, ['all', 'amazon_dsp']);
            this.hasFB = this.authService.hasFeature(mediaConnectorFeature?.value, ['all', 'fb_ads']);
            this.hasXDR = this.authService.hasFeature(mediaConnectorFeature?.value, ['all', 'xandr']);
            this.hasTKTK = this.authService.hasFeature(mediaConnectorFeature?.value, ['all', 'tiktok_ads']);

            if (component === 'dv360') {
                this.mediaToCheck(this.hasDV)
            } else if (component === 'amazon') {
                this.mediaToCheck(this.hasDSP)
            } else if (component === 'fbads') {
                this.mediaToCheck(this.hasFB)
            } else if (component === 'xandr') {
                this.mediaToCheck(this.hasXDR)
            }else if (component === 'tiktok') {
                this.mediaToCheck(this.hasTKTK)
            } else if (component === 'grouping') {
                if (this.hasDSP && !this.hasDV && !this.hasFB && !this.hasXDR && !this.hasTKTK) {
                    this.router.navigate(['/error/401']);
                    return false;
                }
            }
        } else {
            // If not authorized, redirect to 401 error page
            this.router.navigate(['/error/401']);
            return false;
        }
    }

    public mediaToCheck(media: boolean) {
        if (media) {
            return true
        } else {
            this.router.navigate(['/error/401']);
            return false;
        }
    }
}
