import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {Observable} from "rxjs";
import {SharedFunctionsService} from "../../../../shared";

const API_URL = `${environment.apiUrl}/users`;

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient,
              private sharedFunctionsService:SharedFunctionsService) {
  }

  PostUser(user: any) {
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    return this.http.post<any>(`${API_URL}/save`, user,{headers});
  }

  getUsers(): Observable<any[]> {
    return this.http.get<any[]>(`${API_URL}`);
  }

  updateUser(user: any, licenceChanges: any, removedPartners: any, advertisersChanges: any): Observable<any> {
    const userData = {user, licenceChanges, removedPartners, advertisersChanges};
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    return this.http.put<any>(`${API_URL}/update`, userData,{headers});
  }

  deleteUser(id: string): Observable<number> {
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    return this.http.delete<number>(`${API_URL}/delete?id=${id}`,{headers});
  }

  updateStatus(data: any) {
    const headers = this.sharedFunctionsService.setHeaders('check-user')
    return this.http.post<any>(`${API_URL}/status`, data,{headers});
  }
}
