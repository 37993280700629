import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription, throwError} from 'rxjs';
import {catchError, finalize, map, switchMap} from 'rxjs/operators';
import {UserModel} from '../models/user.model';
import {AuthModel} from '../models/auth.model';
import {Router} from '@angular/router';
import {AuthHTTPService} from "./auth-http";
import {Partner} from "../../administration";
import {Permission} from "../../administration/role-management/models/Permission.model";
import {DynamicRole} from "../../administration/role-management/models/DynamicRole.model";
import {environment} from "../../../../environments/environment";
import {ModuleAccess} from "../../administration/role-management/enums/ModuleAccess";
import {AccessLevel} from "../../administration/role-management/enums/AccessLevel";

export type UserType = UserModel | undefined;
export type PartnerType = Partner | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  // public fields
  currentUser$: Observable<UserType>;
  currentPartner$: Observable<PartnerType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  currentPartnerSubject: BehaviorSubject<PartnerType | undefined>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  currentUserValueFromDB(): Observable<UserType | undefined> {
    return this.getUserByToken().pipe(
      catchError((err) => {
        if (err.error === "User BLOCKED") {
          this.logout();
        }
        return of(undefined);
      })
    )

  }


  get currentPartnerValue(): PartnerType {
    return this.currentPartnerSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentPartnerSubject = new BehaviorSubject<PartnerType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.currentPartner$ = this.currentPartnerSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    /* const subscr = this.getUserByToken().subscribe();
     this.unsubscribe.push(subscr);*/
  }

  // public methods
  login(email: string, password: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        if (err.error == "User BLOCKED") {
          return throwError(() => new Error(err.error.text));
        } else {
          return of(undefined);
        }
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  loginWithGoogle(token: string): Observable<any> {

    this.isLoadingSubject.next(true);
    return this.authHttpService.loginWithGoogle(token).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      finalize(() => this.isLoadingSubject.next(false))
    );

  }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    localStorage.removeItem('cp');
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    this.isLoadingSubject.next(true);

    return this.authHttpService.getUserByToken(auth.authToken).pipe(
      map((user: UserType) => {
          if (!user) {
            this.logout();
            return of(undefined);
          }

          //Update the media_connector_bk feature .
         this.updateMediaConnectorFeature(user);

          // Update role and user in local storage and subjects
          localStorage.setItem('role', user.roles[0]?.name || '');
          this.currentUserSubject.next(user);

          if(user.partners.length){
            // Determine current partner
            const enabledPartners = user.partners.filter((partner: any) => partner.isEnabled);
            const storedPartner = this.getCurrentPartnerFromLocalStorage() || enabledPartners[0];

            if (!this.getCurrentPartnerFromLocalStorage()) {
              this.setCurrentPartnerFromLocalStorage(enabledPartners[0]);
            }

            this.currentPartnerSubject.next(storedPartner);
          }else{
            localStorage.removeItem('cp')
          }

          return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  updateMediaConnectorFeature(user: any): void {
    let mediaConnectorBk = user.apifewResponse.license_features.find(
      (feature: any) => feature.name === 'media_connector_bk'
    );

    let newValues: any[] = [];

    if (!mediaConnectorBk || (mediaConnectorBk && this.parseFeatureValue(mediaConnectorBk.value).en !== 'all')) {
      user.apifewResponse.license_sublicenses.forEach((obj: any) => {
        const planName = obj.pricing_name.toLowerCase();

        const newMappings: Record<string, string> = {
          fb: 'fb_ads',
          amazon: 'amazon_dsp',
          xandr: 'xandr',
          tiktok: 'tiktok_ads',
          360: 'dv360',
        };

        for (const [key, value] of Object.entries(newMappings)) {
          if (planName.includes(key) && !newValues.includes(value)) {
            newValues.push(value);
          }
        }
      });
    }

    const parsedValue = this.parseFeatureValue(mediaConnectorBk?.value);
    if (parsedValue?.en === 'all') {
      newValues = ['all'];
    }

    // Remove old media_connector_bk feature if it exists
    user.apifewResponse.license_features = user.apifewResponse.license_features.filter(
      (feature: any) => feature.name !== 'media_connector_bk'
    );

    // Add the new updated media_connector_bk feature (with the new values)
    user.apifewResponse.license_features.push({
      name: 'media_connector_bk',
      value: {
        en: newValues,
      },
    });
  }

  getUserByTokenForSetupPassword(): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.authToken).pipe(
      map((user: any) => {
        if (!user) {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  signUp(user: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.signUp(user).pipe(
      map((response: any) => {
        // Check for success response
        if (response) {
          this.isLoadingSubject.next(false);
          return response; // Return data if successful
        }
      }),
      catchError(error => {
        // Catch any errors during HTTP request
        return throwError(error);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.authToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  public setCurrentPartnerFromLocalStorage(partner: Partner): boolean {
    /** store current partner  in local storage to keep user current partner in between page refreshes */
    if (partner) {
      localStorage.setItem('cp', JSON.stringify(partner));
      return true;
    }
    return false;
  }

  public getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public getCurrentPartnerFromLocalStorage(): Partner | undefined {
    try {
      const lsValue = localStorage.getItem('cp');
      if (!lsValue) {
        return undefined;
      }

      const currentPartner = JSON.parse(lsValue);
      return currentPartner;
    } catch (error) {
      return undefined;
    }
  }

  updatePassword(email: string, password: string): Observable<UserType> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.updatePassword(email, password);
  }

  /**
   * Method to check if a token has expired based on the token ;
   * @param token : string
   */
  checkTokenExpiration(token: string): Observable<any> {
    return this.authHttpService
      .checkTokenExpiration(token);
  }

  /**
   * Method to check if a token has expired based on the expiration date;
   * @param expiresIn : string
   */
  isTokenExpired(expiresIn: string | undefined | Date): boolean {
    if (!expiresIn) return true; /** Token not present, consider it expired */

    /** Parse expiration date from string */
    const expirationDate = new Date(expiresIn);

    /** Get current date */
    const currentDate = new Date();

    /** Compare expiration date with current date */
    return expirationDate < currentDate;
  }

  /**
   * Checks if the current user has any of the expected permissions.
   * @param expectedPermissions An optional array of `Permission` objects representing the permissions that the user is expected to have.
   * @returns A boolean indicating whether the user has at least one of the expected permissions (`true`) or not (`false`).
   */
  checkIfUserHasExpectedPermissions(expectedPermissions?: Permission[] | undefined): boolean {
    const currentUserRoles = this.currentUserValue?.roles as DynamicRole[];

    if (!expectedPermissions || expectedPermissions.length === 0) {
      return false;
    }

    return currentUserRoles?.some(role =>
      role.permissions?.some(permission =>
        expectedPermissions.some(expectedPermission =>
          permission.moduleAccess === expectedPermission.moduleAccess &&
          permission.accessLevel === expectedPermission.accessLevel
        )
      )
    );
  }

  checkIfUserHasAllTheExpectedPermissions(expectedPermissions?: Permission[] | undefined): boolean {
    const currentUserRoles = this.currentUserValue?.roles as DynamicRole[];

    if (!expectedPermissions || expectedPermissions.length === 0) {
      return false;
    }
    return expectedPermissions.every(expectedPermission =>
      currentUserRoles?.some(role =>
        role.permissions?.some(permission =>
          permission.moduleAccess === expectedPermission.moduleAccess &&
          permission.accessLevel === expectedPermission.accessLevel
        )
      )
    );
  }

  getPermissionsForRole(moduleAccess: ModuleAccess): { moduleAccess: ModuleAccess; accessLevel: AccessLevel }[] {
    return [
      { moduleAccess, accessLevel: AccessLevel.READ },
      { moduleAccess, accessLevel: AccessLevel.UPDATE },
      { moduleAccess, accessLevel: AccessLevel.DELETE },
      { moduleAccess, accessLevel: AccessLevel.CREATE },
    ];
  }

  hasFeature(featureValue: any, keys: string[]): boolean {
    return keys.some(key => featureValue.en.includes(key));
  }

  parseFeatureValue(value: any): any {
    if (typeof value === 'object') {
      return value;
    } else if (typeof value === 'string') {
      try {
        return JSON.parse(value);
      } catch (error) {
        console.error('Invalid JSON value:', value, error);
        return {};
      }
    }
    return {};
  }


  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
