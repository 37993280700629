import { Injectable } from '@angular/core';
import {loadUsetifulScript, setUsetifulTags} from "usetiful-sdk";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UsetifulService {
  token : string = `${environment.usetifulToken}`;
  constructor() {
    this.loadScript();
  }

  private loadScript(): void {
    loadUsetifulScript(this.token);
  }

  setUserTags(userId: string, firstName: string, lastName: string, role: string): void {
    setUsetifulTags({
      userId,
      firstName,
      lastName,
      role
    });
  }
}
