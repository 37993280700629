import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {AuthService, PartnerType, UserType} from "../../../../../../../auth";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
  AdvertiserService,
  CampaignService,
  FormValidationService,
  SwalNotificationsService
} from "../../../../../../../../shared";
import {Subscription} from "rxjs";
import {GroupService, SettingService} from "../../../";
import {MediaType} from "../../../../../../../administration";

@Component({
    selector: 'app-group-modal',
    templateUrl: './group-modal.component.html',
    styleUrl: './group-modal.component.scss'
})
export class GroupModalComponent implements OnInit, OnDestroy {
    groupForm !: FormGroup;
    user: UserType;
    group: any;
    SaveBtnLabel: string = 'Save';
    modalLabel: string = 'New group';
    Campaigns: any[] = [];
    listCampaigns: any[] | undefined = [];
    private oldSelectedCampaigns: any[];
    private unsubscribe: Subscription[] = [];
    RevomedAdvertisers: any[] = []
    advertisers: any[] = [];
    copyAdvertisers: any[] = [];
    isCheckedAllCampaigns: boolean = false;
    isCheckedAllAdvertisers: boolean = false;
    currentPartner: PartnerType = undefined
    currentUser: UserType = undefined;
    show: boolean = false
    public hasDV: boolean = false;
    public hasFB: boolean = false;
    public hasXDR: boolean = false;
    public hasTKTK: boolean = false;
    public hasDSP: boolean = false;
    mediasThatHasAccessTo: string[] = [];
    disableSelectMediaMenu = false;
    advertisersSpinner = false;
    campaignsSpinner = false;
    selectedPlatforms: MediaType[] = []; // Array to store selected media platforms
    CampaignsTokeep : any = []
    CampaignsToDelete : any = []
    othersGroups : any


    /**
     * @param formBuilder
     * @param formValidation
     * @param dialogRef
     * @param dialogData
     * @param advertiserService
     * @param authService
     * @param campaignService
     * @param swalNotifs
     * @param groupsSercices
     * @param settingService
     */
    constructor(private formBuilder: FormBuilder,
                private formValidation: FormValidationService,
                private dialogRef: MatDialogRef<GroupModalComponent>,
                @Inject(MAT_DIALOG_DATA) public dialogData: any,
                private advertiserService: AdvertiserService,
                private authService: AuthService,
                private campaignService: CampaignService,
                private swalNotifs: SwalNotificationsService,
                private groupsSercices: GroupService,
                private settingService: SettingService
    ) {
        this.subscribeToCurrentPartnerAndUser();
    }

    /**
     * Unsubscribe from all subscriptions when the component is destroyed.
     */
    ngOnDestroy(): void {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }

    ngOnInit(): void {
        this.dialogRef.disableClose = true;
    }
    formInit() {
        this.groupForm = this.formBuilder.group(
            {
                id: '',
                partnerId: '',
                userId: '',
                partnerName: '',
                campaigns: ['', Validators.required],
                medias:[''],
                advertisers: ['', Validators.required],
                groupName: ['', [
                    Validators.required,
                    forbiddenNameValidator('Others'),
                    forbiddenNameValidator('Other')]
                ],
                settingId: [null],
            }
        )
        if (this.dialogData.group) {
            this.selectedPlatforms = this.dialogData.group.medias.split(',')
            this.disableSelectMediaMenu = true;
            let selectedCampaigns : any = []
            let unselectedCampaigns : any = []
            if(this.selectedPlatforms.length === 1) {
              selectedCampaigns = this.dialogData.selectedCampaignsForMedia.filter((camp: any) =>  this.selectedPlatforms.toString() === camp.mediaName)
              unselectedCampaigns = this.dialogData.unselectedCampaignsForMedia.filter((camp: any) =>  this.selectedPlatforms.toString() === camp.mediaName)
            }else{
              selectedCampaigns = this.dialogData.selectedCampaignsForCross
              unselectedCampaigns = this.dialogData.unselectedCampaignsForCross
            }
            let advertisersByMedia = this.removeDuplicatesByAdvertiserId(selectedCampaigns.concat(unselectedCampaigns))?.filter((ad) => this.dialogData.group.medias.includes(ad.mediaName))
            this.modalLabel = 'Edit group';
            this.SaveBtnLabel = 'Edit';
            this.groupForm.controls.id.setValue(this.dialogData.group.id);
            this.groupForm.controls.groupName.setValue(this.dialogData.group.groupName);
            this.groupForm.controls.partnerName.setValue(this.dialogData.group.partnerName);
            this.groupForm.controls.campaigns.setValue(this.dialogData.group.campaigns);
            this.groupForm.controls.advertisers.setValue(advertisersByMedia);
            this.groupForm.controls.partnerId.setValue(this.dialogData.group.partnerId);
            this.groupForm.controls.userId.setValue(this.dialogData.group.userId);
            this.groupForm.controls.medias.setValue(this.dialogData.group.medias);
            this.selectedPlatforms = this.dialogData.group.medias.split(',');
            /** save old selected campaigns for group to edit */
            this.oldSelectedCampaigns = this.dialogData.group.campaigns
            this.othersGroups = this.dialogData.groups
            this.getCampaigns('campaigns')
        }
        this.show = true;
    }

    submit() {
        if (this.groupForm.invalid) {
            this.formValidation.validateAllFormFields(this.groupForm);
            return;
        }
        if (this.groupForm.valid) {
            if (this.dialogData.group) {
                this.updateGroup();
            } else {
                this.saveGrousaveGp()
            }
        }
    }

    /**
     * Saves the group along with its associated campaigns.
     */
    private saveGrousaveGp() {

        if (this.currentPartner && this.currentUser) {
            this.groupForm.controls.partnerId.setValue(this.currentPartner.id);
            this.groupForm.controls.userId.setValue(this.currentUser.id);
            this.groupForm.controls.partnerName.setValue(this.currentPartner.name);
        }
          let selectedCampaigns : any[] = []
          let unselectedCampaigns : any[] = []
          let combinedArray : any[] = []
          let resCampaigns : any[] = []
          combinedArray
      if(this.selectedPlatforms.length === 1) {
        selectedCampaigns = this.dialogData.selectedCampaignsForMedia.filter((camp: any) =>  this.selectedPlatforms.toString() === camp.mediaName)
        unselectedCampaigns = this.dialogData.unselectedCampaignsForMedia.filter((camp: any) =>  this.selectedPlatforms.toString() === camp.mediaName)
        combinedArray = this.groupForm.controls.campaigns.value.concat(selectedCampaigns.filter((cmp: any) => this.selectedPlatforms.toString() === cmp.mediaName))
        // @ts-ignore
        resCampaigns = this.removeDuplicatesByDvCampaignId(this.listCampaigns.concat(unselectedCampaigns.filter((cmp: any) => this.selectedPlatforms.toString() === cmp.mediaName))).filter(item => !combinedArray.some(selectedItem => selectedItem.mediaCampaignId === item.mediaCampaignId));
      }else{
        selectedCampaigns = this.dialogData.selectedCampaignsForCross
        unselectedCampaigns = this.dialogData.unselectedCampaignsForCross
        combinedArray = this.groupForm.controls.campaigns.value.concat(selectedCampaigns)
        // @ts-ignore
        resCampaigns = this.removeDuplicatesByDvCampaignId(this.listCampaigns.concat(unselectedCampaigns)).filter(item => !combinedArray.some(selectedItem => selectedItem.mediaCampaignId === item.mediaCampaignId));
      }
        this.groupsSercices.saveGroup({group: this.groupForm.value, campaigns: resCampaigns}).subscribe(
            {
                next: (response) => {
                    // @ts-ignore
                    this.group = {group: this.groupForm.value, campaigns: resCampaigns};
                    this.resetAndCloseForm();
                    this.swalNotifs.successNotification("Group added successfully");
                },
                error: (error) => {
                    this.swalNotifs.errorNotification(error.error)
                }
            }
        )
    }

    /**
     * Updates the group along with its associated campaigns.
     */
    private updateGroup() {
      let selectedCampaigns : any[] = []
      let unselectedCampaigns : any[] = []
      let combinedArray : any[] = []
      let resCampaigns : any[] = []
      if(this.selectedPlatforms.length === 1) {
        selectedCampaigns = this.dialogData.selectedCampaignsForMedia.filter((camp: any) =>  this.selectedPlatforms.toString() === camp.mediaName)
        unselectedCampaigns = this.dialogData.unselectedCampaignsForMedia.filter((camp: any) =>  this.selectedPlatforms.toString() === camp.mediaName)
        combinedArray = this.groupForm.controls.campaigns.value.concat(selectedCampaigns.filter((item:any)=>!this.CampaignsTokeep.some((selectedItem:any) => selectedItem.mediaCampaignId === item.mediaCampaignId)).filter((cmp: any) => this.selectedPlatforms.toString() === cmp.mediaName))
        // @ts-ignore
        resCampaigns = this.removeDuplicatesByDvCampaignId(this.listCampaigns.concat(unselectedCampaigns.concat(this.CampaignsTokeep).filter(ad1 =>!this.CampaignsToDelete.some(ad2=>ad1.advertiserId===ad2.advertiserId))
          .filter((cmp: any) => this.selectedPlatforms.includes(cmp.mediaName)))).
          // @ts-ignore
          filter(item => !combinedArray.some(selectedItem => selectedItem.mediaCampaignId === item.mediaCampaignId));
      }else{
        selectedCampaigns = this.dialogData.selectedCampaignsForCross
        unselectedCampaigns = this.dialogData.unselectedCampaignsForCross
        combinedArray = this.groupForm.controls.campaigns.value.concat(selectedCampaigns.filter((item:any)=>!this.CampaignsTokeep.some((selectedItem:any) => selectedItem.mediaCampaignId === item.mediaCampaignId)))
        // @ts-ignore
        resCampaigns = this.removeDuplicatesByDvCampaignId(this.listCampaigns.concat(unselectedCampaigns.concat(this.CampaignsTokeep).filter(ad1 =>!this.CampaignsToDelete.some(ad2=>ad1.advertiserId===ad2.advertiserId)))).
          // @ts-ignore
          filter(item => !combinedArray.some(selectedItem => selectedItem.mediaCampaignId === item.mediaCampaignId));
      }

      this.groupsSercices.updateGroup({group: this.groupForm.value, campaigns: resCampaigns}).subscribe(
            {
                next: (response) => {
                    // @ts-ignore
                    this.group = {group: this.groupForm.value, campaigns: resCampaigns};
                    this.resetAndCloseForm();
                    this.swalNotifs.successNotification("Group updated successfully");
                },
                error: (error) => {
                    this.swalNotifs.errorNotification(error.error)
                }
            }
        )

    }


    resetAndCloseForm() {
        this.groupForm.reset();
        this.dialogRef.close(this.group);
    }

    validateName() {
        return this.groupForm.controls.groupName.valid || this.groupForm.controls.groupName.untouched;
    }

    validateCampaigns() {
        return this.groupForm.controls.campaigns.valid || this.groupForm.controls.campaigns.untouched;
    }

    validateAdvertisers() {
        return this.groupForm.controls.advertisers.valid || this.groupForm.controls.advertisers.untouched;
    }

    public getCampaigns(from?:string) {
        if (this.currentPartner) {
            this.campaignsSpinner = !this.campaignsSpinner
            this.Campaigns = []
            let advertisersByMedia = this.removeDuplicatesByAdvertiserId(this.groupForm.controls.advertisers.value)/*?.filter((ad) => this.selectedPlatforms.includes(ad.mediaName))*/
            let dvRequest = {
                dvPartnerId: this.currentPartner.id,
                dvPartnerName: this.currentPartner.name,
                advertisers: advertisersByMedia,
            }
            const campaignsSub = this.campaignService.getCampaigns(dvRequest,from)
                .subscribe({
                  next: (res: any[]) => {
                    let selectedCampaigns : any[] = []
                    if(this.selectedPlatforms.length === 1) {
                      selectedCampaigns = this.dialogData.selectedCampaignsForMedia.filter((camp: any) =>  this.selectedPlatforms.toString() === camp.mediaName)
                    } else{
                      selectedCampaigns = this.dialogData.selectedCampaignsForCross
                    }
                    this.Campaigns = res
                    // @ts-ignore
                    this.listCampaigns = res
                    // @ts-ignore
                    this.campaignsSpinner = !this.campaignsSpinner
                    // @ts-ignore
                    this.Campaigns = this.listCampaigns.filter(item => !selectedCampaigns.some(selectedItem => selectedItem.mediaCampaignId === item.mediaCampaignId));
                    let oldSelectedCampaigns  =  []
                    if(this.selectedPlatforms.length === 1) {
                      oldSelectedCampaigns = this.oldSelectedCampaigns?.filter((camp: any) => this.selectedPlatforms.toString() === camp.mediaName)
                    }else{
                      oldSelectedCampaigns = this.oldSelectedCampaigns
                    }
                    if (oldSelectedCampaigns?.length) this.Campaigns = (this.Campaigns.concat(oldSelectedCampaigns)).filter((camp)=>this.groupForm.controls.advertisers.value.some((ad:any)=>camp.advertiserId===ad.advertiserId))
                  },
                  error : ()=>{
                    this.campaignsSpinner = !this.campaignsSpinner
                  }
                })
            this.unsubscribe.push(campaignsSub);
        }
    }

    getAdvertisers(from?:string) {
        if (this.currentPartner && this.currentUser) {
            this.advertisers = []
            this.advertisersSpinner = !this.advertisersSpinner
            /**  userId */
            this.advertiserService.getAdvertisersByPartnerIdAndUserId(Number(this.currentPartner.id), this.currentUser.id.toString(),from)
                .subscribe({
                    next: (res) => {
                        this.advertisers = this.copyAdvertisers = res.map(gr => {return {...gr , formattedMediaName : this.getFormattedMediaName(gr.mediaName) }})
                        this.advertisersSpinner = !this.advertisersSpinner
                        this.updateAdvertisersList(this.selectedPlatforms)
                    }
                })
        }
    }

    updateAndDisableMediaSelectionMenuIfItComesFromAlertPage() {
        if (this.dialogData.comingFromAlertPage) {
              const selectedMediaName = this.settingService.settingForm?.controls["mediaName"].value;

              if(selectedMediaName !== MediaType.CROSS_PLATFORM){
                  this.selectedPlatforms.push(selectedMediaName)
                  this.groupForm.controls.medias.setValue(selectedMediaName)
                  this.disableSelectMediaMenu = true;
                  this.updateAdvertisersList(selectedMediaName)
              }
        }
    }

    /**
     * Removes duplicates from an array of objects based on the 'advertiserName' property.
     * @param dataArray The array from which duplicates are to be removed.
     * @returns The array with duplicates removed based on the 'advertiserName' property.
     */
    removeDuplicatesByAdvertiserId(dataArray: any[]) {
        if (dataArray.length) {
            return dataArray.filter(
                (obj, index, self) =>
                    index ===
                    self.findIndex((o) => o.advertiserId === obj.advertiserId)
            );
        }
    }

    /**
     * Removes duplicates from an array of objects based on the 'dvCampaignId' property.
     * @param dataArray The array from which duplicates are to be removed.
     * @returns The array with duplicates removed based on the 'dvCampaignId' property.
     */
    removeDuplicatesByDvCampaignId(dataArray: any[]) {
        if (dataArray.length) {
            return dataArray.filter(
                (obj, index, self) =>
                    index ===
                    self.findIndex((o) => o?.mediaCampaignId === obj?.mediaCampaignId)
            );
        }
    }
    /**
     * Toggles the selection of all items in the specified group.
     * @param $event The event object containing the checkbox state.
     * @param forSelect The target selection group ('advertisers' or 'campaigns').
     */
    toggleSelectAll($event: any, forSelect: string) {
        if (forSelect == 'advertisers') {
            if ($event.target.checked) {
                this.groupForm.controls.advertisers.setValue(this.advertisers)
                this.isCheckedAllAdvertisers = true
            } else {
                this.groupForm.controls.advertisers.reset()
                this.isCheckedAllAdvertisers = false
            }
        } else {
            if ($event.target.checked) {
                this.groupForm.controls.campaigns.setValue(this.Campaigns)
                this.isCheckedAllCampaigns = true
            } else {
                this.groupForm.controls.campaigns.reset()
                this.isCheckedAllCampaigns = false
            }
        }
    }

    /**
     * Checks if all items are selected for either campaigns or advertisers.
     * @param forSelect Indicates whether to check for campaigns or advertisers.
     */
    isCheckedAll(forSelect: string,ev?:any) {
        if (forSelect == 'campaigns') {
            if (this.groupForm.controls.campaigns?.value?.length == this.Campaigns.length) this.isCheckedAllCampaigns = true
            else this.isCheckedAllCampaigns = false
        } else {
            if (this.groupForm.controls.advertisers?.value?.length == this.advertisers.length) this.isCheckedAllAdvertisers = true
            else this.isCheckedAllAdvertisers = false
        }
    }

    /**
     * Clears all selections for either campaigns or advertisers.
     * @param forSelect Indicates whether to clear selections for campaigns or advertisers.
     * @param previousSelection .
     */
    clearAll(forSelect: string,previousSelection : any) {
        if (forSelect == 'campaigns') {
          this.isCheckedAllCampaigns = false
        }
        else {
          console.log('previousSelection',previousSelection)
          this.isCheckedAllAdvertisers = false
        }
    }

    /**
     * Adds removed advertisers to the list.
     * @param advertiserId The removed advertiserId .
     */
    revomedAdvertisers(advertiserId: string) {

      const CampaignsSelected = this.groupForm.controls.campaigns.value
      if(CampaignsSelected){
        const filteredCampaigns = CampaignsSelected?.filter((cmp: any) => cmp.advertiserId !== advertiserId);
        this.groupForm.controls.campaigns.setValue(filteredCampaigns);
      }
       this.listCampaigns = this.listCampaigns?.filter((cmp) => cmp.advertiserId !== advertiserId)
      /**
       * Checks if the advertiser is already used by other groups.
       * @isAdvertiserUsedInOtherGroups will return `true` if the advertiser exists in other groups, otherwise `false`.
       */
      const isAdvertiserUsedInOtherGroups : boolean = this.dialogData.groups
                .flatMap((group: any) => group.campaigns)
                .some((camp:any) =>camp.advertiserId === advertiserId)

      if(isAdvertiserUsedInOtherGroups){
        this.CampaignsTokeep.push(...CampaignsSelected.filter((cm: any) => cm.advertiserId === advertiserId));
      }else{
        this.CampaignsToDelete.push({advertiserId:advertiserId})
      }
    }

  /**
   *
   */
  revomedCampaigns(cam:any){
    this.CampaignsTokeep.push(cam)
  }

  /**
   *
   */

  /**
     * Removes the added advertiser from the list.
     * @param $event The added advertiser to be removed from the list.
     */
    addAdvertiser($event: any) {
        this.RevomedAdvertisers = this.RevomedAdvertisers.filter(advertiser => advertiser.advertiserName !== $event.advertiserName);
    }

    /**
     * Function to subscribe to changes in current partner and Current user
     * @private Function
     */
    private subscribeToCurrentPartnerAndUser() {
        const currentPartnerSub = this.authService.currentPartner$.subscribe({
            next: (partner) => {
                this.currentPartner = partner;
            }
        })
        /** Add subscription to unsubscribe array for cleanup */
        this.unsubscribe.push(currentPartnerSub)

        /** Subscribe to current user */
        const currentUserSub = this.authService.currentUser$.subscribe({
            next: (user) => {
                this.currentUser = user
                const features = user?.apifewResponse?.license_features || [];

                if (user && features) {
                  const mediaConnectorFeature = features.find((lf: any) => lf.name === 'media_connector_bk');

                    if (this.authService.hasFeature(mediaConnectorFeature?.value, ['all', 'dv360'])) {
                        this.mediasThatHasAccessTo.push('dv360');
                        this.hasDV = true;
                    }

                    if (this.authService.hasFeature(mediaConnectorFeature?.value, ['all', 'fb_ads'])) {
                        this.mediasThatHasAccessTo.push('fbads');
                        this.hasFB = true;
                    }

                    if (this.authService.hasFeature(mediaConnectorFeature?.value, ['all', 'xandr'])) {
                        this.mediasThatHasAccessTo.push('xandr');
                        this.hasXDR = true;
                    }

                    if (this.authService.hasFeature(mediaConnectorFeature?.value, ['all', 'tiktok_ads'])) {
                        this.mediasThatHasAccessTo.push('tiktok');
                        this.hasTKTK = true;
                    }

                    if (this.authService.hasFeature(mediaConnectorFeature?.value, ['all', 'amazon_dsp'])) {
                      this.mediasThatHasAccessTo.push('amz');
                      this.hasDSP = true;
                    }
                }
                this.formInit();
                this.updateAndDisableMediaSelectionMenuIfItComesFromAlertPage()
            }
        });
        /** Add subscription to unsubscribe array for cleanup */
        this.unsubscribe.push(currentUserSub);
    }

    // toggle platform selection
    togglePlatformSelection(platform: MediaType): void {
        if (this.disableSelectMediaMenu) return;
        this.RevomedAdvertisers = [];
        this.groupForm.controls.campaigns.reset();
        this.groupForm.controls.advertisers.reset();
        this.Campaigns = []
        const index = this.selectedPlatforms.indexOf(platform);
        if (index === -1) {
            this.selectedPlatforms.push(platform); // Add platform if not selected
        } else {
            this.selectedPlatforms.splice(index, 1); // Remove platform if already selected
        }
        this.groupForm.controls.medias.setValue(this.selectedPlatforms.toString())
    }

    private updateAdvertisersList(selectedPlatforms: MediaType[]) {
        this.advertisers = this.copyAdvertisers.filter((ad) =>  selectedPlatforms.includes(ad.mediaName))
    }

    compareFnCampaigns(item1: any, item2: any): boolean {
        return item1.mediaCampaignId === item2.mediaCampaignId;
    }

    compareFnAdvertisers(item1: any, item2: any): boolean {
        return item1.advertiserId === item2.advertiserId;
    }
    getFormattedMediaName(mediaName: MediaType): string {
        const mediaNames: any = {
            [MediaType.DISPLAY_VIDEO]: 'Display Video',
            [MediaType.FACEBOOK_ADS]: 'Meta',
            [MediaType.XANDR]: 'Xandr',
            [MediaType.TIKTOK]: 'TikTok',
            [MediaType.AMAZON_DSP]: 'Amazon'
        };

        return mediaNames[mediaName] || '';
    }

    protected readonly MediaType = MediaType;


}

export function forbiddenNameValidator(forbiddenName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }

        const isForbidden = control.value.trim().toLowerCase() === forbiddenName.toLowerCase();
        return isForbidden ? { forbiddenName: { value: control.value } } : null;
    };
}
