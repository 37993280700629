export enum ModuleAccess {
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  PARTNER_MANAGEMENT = 'PARTNER_MANAGEMENT',
  PARTNER_CONFIGURATION = 'PARTNER_CONFIGURATION',
  ADVERTISER_MANAGEMENT = 'ADVERTISER_MANAGEMENT',
  LICENSE_MANAGEMENT = 'LICENSE_MANAGEMENT',
  ROLE_MANAGEMENT = 'ROLE_MANAGEMENT',
  AK_SETTINGS_MANAGEMENT = 'AK_SETTINGS_MANAGEMENT',
  BK_SETTINGS_MANAGEMENT = 'BK_SETTINGS_MANAGEMENT',
  BK_GROUPING_MANAGEMENT = 'BK_GROUPING_MANAGEMENT',
  BANNER_EDITOR= "BANNER_EDITOR",
  CONTACT_US= "CONTACT_US",
  GTM_ALERT_MANAGEMENT="GTM_ALERT_MANAGEMENT",
  GTM_MAILING_CONFIGURATION= "GTM_MAILING_CONFIGURATION"
}
