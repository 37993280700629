import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, take, throwError} from 'rxjs';
import {environment} from "../../../../../environments/environment";
import {catchError, finalize, switchMap} from "rxjs/operators";
import {LoaderService, SwalNotificationsService} from "../../../../shared";
import {TokenService} from "../token.service";
import {AuthHTTPService} from "../auth-http";
import {AuthService} from "../auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  constructor(private loaderService: LoaderService,
              private swalNotifs: SwalNotificationsService,
              private tokenExpiredService: TokenService,
              private authHTTPService: AuthHTTPService ,
             private authService : AuthService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const showLoader = !request.headers.has('Skip-Loader');

    /**
     * Show loader
     */

    this.showLoader(request, showLoader)

    /**
     * Skip interception for specific URLs (login, sign-up, etc.)
     */

    if (this.shouldSkipRequest(request.url)) {
      return next.handle(request).pipe(
        finalize(() => {
          this.hideLoader(request, showLoader)
        })
      );
    }

    /**
     * Retrieve token from localStorage;
     */
    const lsValue = localStorage.getItem(this.authLocalStorageToken);
    let token = '';
    if (lsValue) {
      token = JSON.parse(lsValue).authToken;
    }

    /**
     * Previous role of the user
     */
    let role = localStorage.getItem('role')

    /**
     * Constant to check if the 'check-user' header exists
     */
    const hasCheckUserHeader = request.headers.has('check-user');

    /**
     * Check if 'check-user' header is present
     */
    if (hasCheckUserHeader) {
      /**
       * Fetch fresh user data before modifying the request.
       */
      return this.verifyUserAndModifyRequestWithToken(request,next,token,role,showLoader);

    } else {
      /**
       * If 'check-user' header is not present, modify the request.
       */
      return  this.modifyRequestWithToken(request,next,token,showLoader)

    }
  }

  private shouldSkipRequest(url: string): boolean {
    return url.includes('/login') || url.includes('/sign-up') || url.includes('/contact-us/send') ||
      url.includes('/forgot-password') || url.includes('/new-password') ||
      url.endsWith('/user') || url.includes('/check-token-expiration');
  }

  private hideLoader(request: HttpRequest<any>, showLoader: boolean): void {
    if (request.url.includes('lunchChecks')) {
      this.loaderService.requestMonitoringEnded();
    } else if (showLoader) {
      this.loaderService.hide();
    }
  }

  private showLoader(request: HttpRequest<any>, showLoader: boolean): void {
    if (request.url.includes('lunchChecks')) {
      this.loaderService.requestMonitoringStarted();
    } else if (showLoader) {
      this.loaderService.show();
    }
  }

  private isUserValid(user: any, role: string | null): boolean {
    return user && user.status !== 'BLOCKED' &&
      !this.tokenExpiredService.isTokenExpired(this.tokenExpiredService.getAuthFromLocalStorage()?.expiresIn) &&
      role === user.roles[0].name;
  }

  private modifyRequestWithToken(request: HttpRequest<any>, next: HttpHandler, token: string, showLoader: boolean): Observable<HttpEvent<any>> {

    /**
     * Clone the request and add the token header if token is available
     */

    let modifiedRequest = request;
    if (token) {
      modifiedRequest = request.clone({
        setHeaders: {Authorization: `Bearer ${token}`}
      });
    }

    /**
     * Pass the modified request instead of the original request
     */

    return next.handle(modifiedRequest).pipe(
      finalize(() =>
        this.hideLoader(request, showLoader)
      )
    );
  }
  private verifyUserAndModifyRequestWithToken(request: HttpRequest<any>, next: HttpHandler, token: string, role: string | null, showLoader: boolean): Observable<HttpEvent<any>>{
   return  this.authHTTPService.getUserByToken(token).pipe(
      take(1),
      switchMap(user => {
        if (this.isUserValid(user, role)) {
          /**
           * Checks if the current partner is deleted; if so, prompts the user to refresh.
           * and blocks the request if needed.
           */
          const shouldBlockRequest = this.updateCurrentPartnerAndRefreshPage(user);
          if (shouldBlockRequest) {
            return throwError(() => new Error('Request blocked!'));
          }

          /**
           * If the user is not blocked, modify the request.
           */
          return this.modifyRequestWithToken(request,next,token,showLoader)
        } else {
          /**
           * If the user is blocked, log them out and throw an error
           */
          this.tokenExpiredService.logout();
          setTimeout(() => {
            this.swalNotifs.closeAllSwals();
          }, 0);
          return throwError('User is blocked, session expired');
        }
      }),
      catchError((err) => {
        /**
         * Handle error when fetching the user fails.
         */
        if (err?.url?.endsWith('/user')) {
          this.tokenExpiredService.logout();
          setTimeout(() => {
            this.swalNotifs.closeAllSwals();
          }, 0);
        }
        this.hideLoader(request, showLoader)
        return throwError(err);
      })
    );
  }
  updateCurrentPartnerAndRefreshPage(user:any) : boolean{
    let oldCp = JSON.parse(localStorage.getItem('cp') || '{}');

    if(Object.keys(oldCp).length > 0 && user.partners.length){

      const hasCurrentPartner = user.partners.some((partner: any) =>
        partner.id === oldCp.id && partner.name === oldCp.name);

        if(!hasCurrentPartner){
            const newCp = user.partners[0]
            const msgErr = user.partners.length > 0
              ? `The active partner '${oldCp.name}' has been deleted. Please refresh the page and try again with the new active partner '${newCp.name}' or select another partner.`
              : `The active partner '${oldCp.name}' has been deleted. Please refresh the page and try again.`;

            if (newCp) {
              this.authService.setCurrentPartnerFromLocalStorage(newCp);
            }
            this.swalNotifs.errorNotificationWithRefresh(msgErr);

          // Return true to indicate the request should be blocked
          return true;

        }
    }
    // Return false to indicate the request can proceed
    return false;
  }
}
