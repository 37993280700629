export enum MediaType {
  FACEBOOK_ADS = 'FACEBOOK_ADS',
  XANDR = 'XANDR',
  DISPLAY_VIDEO = 'DISPLAY_VIDEO',
  AMAZON_DSP = 'AMAZON_DSP',
  TIKTOK = 'TIKTOK',
  GTM_MONITOR = 'GTM_MONITOR',
  CROSS_PLATFORM = 'CROSS_PLATFORM',
  DEFAULT = "DEFAULT"
}
