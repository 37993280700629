import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CsvExporterService {
  downloadFile(data: any, filename = 'data', csvHeader: any, exportFrom: String) {
    let csvData = this.ConvertToCSV(data, csvHeader, exportFrom);
    let blob = new Blob(['\ufeff' + csvData], {type: 'text/csv;charset=utf-8;'});
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray: any, headerList: any, exportFrom: String) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';

    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        if (exportFrom == 'users') {
          line = this.exportUsers(array, i, head, line);
        } else if (exportFrom == 'partners') {
          line = this.exportPartners(array, i, head, line);
        } else if (exportFrom == 'errors_details') {
          line = this.exportErrorsList(array, i, head, line);
        } else {
          if ((array[i][head] ?? "").includes('"')) {
            line += ',' + `"${array[i][head].replace(/"/g, '""')}"`;
          }else {
            line += ',' + `"${array[i][head]}"`;
          }
        }
      }
      str += line + '\r\n';
    }
    return str;
  }

  private exportUsers(array: any, i: number, head: any, line: string) {
    if (typeof array[i][head] == 'object' && head == 'roles') {
      line += ',' + array[i][head][0]?.name;
    } else if (typeof array[i][head] == 'object' && head == 'partners') {
      let partners = "";
      for (let j in array[i][head]) {
        if (array[i][head].length > 1) {
          partners += array[i][head][j].name + (j != ((array[i][head].length - 1).toString()) ? " - " : "");
        } else
          partners += array[i][head][j].name;

      }
      line += ',' + partners;
    } else if (head == 'createdDate') {
      line += ',' + new Date(array[i]['createdDate']).toLocaleString();
    } else if (head == 'lastConnexion') {
      if (array[i]['lastConnexion']) {
        line += ',' + new Date(array[i]['lastConnexion']).toLocaleString();
      } else {
        line += ',' + '---';
      }
    } else {
      line += ',' + array[i][head];
    }
    return line;
  }

  private exportPartners(array: any, i: number, head: any, line: string) {
    if (head == 'users') {
      line += ',' + array[i]['usersNumber'];
    } else if (head == 'status') {
      if (array[i]['isEnabled'] == true) {
        line += ',' + "Active";
      } else
        line += ',' + "Disabled";

    } else if (typeof array[i][head] == 'object' && head == 'medias') {
      let medias = "";
      for (let j in array[i][head]) {
        if (array[i][head].length > 1) {
          medias += array[i][head][j] + (j === '0' ? " - " : "");
        } else
          medias += array[i][head][j];
      }
      line += ',' + medias;
    } else if (head == 'createdDate') {
      line += ',' + new Date(array[i]['createdDate']).toLocaleString();
    } else {
      line += ',' + array[i][head];
    }
    return line;
  }
  private exportErrorsList(array: any, i: number, head: any, line: string) {
      line += ',' + `"${array[i][head]}"`;
    return line;
  }
}
