<div class="card p-0 container-fluid overflow-hidden">
  <div aria-expanded="true" class="card-header border-0 cursor-pointer" data-bs-toggle="collapse" role="button">
    <div class="card-title m-0 border-0">
      <h3 class="fw-bolder m-0">{{modalLabel}}</h3>
    </div>
    <div class="card-toolbar">
      <i class="fa fa-close" style="font-size:24px" (click)="resetAndCloseForm()"></i>
    </div>
  </div>
  <div class="card-body border-top pt-0 overflow-auto">

    <form class="form-container" [formGroup]="userForm" (submit)="onSubmit()">
      <ng-container>
        <div class="fw-bold fs-3 rotate collapsible my-5 d-block" data-bs-toggle="collapse" role="button"
              [attr.aria-expanded]="!isCollapsed1"
             aria-controls="kt_modal_update_customer_user_info">User Information
          <span class="ms-2 rotate-180"
                (click)="collapse1.toggle()"
                (keydown.enter)="collapse1.toggle()">
            <i class="ki-duotone ki-down fs-3" [ngClass]="{ 'ki-up': !isCollapsed1 }"></i>
          </span>
        </div>
        <div class="collapse show" #collapse1="ngbCollapse" [(ngbCollapse)]="isCollapsed1">
          <div class="row">
            <div class="col-xl-6">
              <label class="col-form-label required fw-bold fs-6" for="user-name">first Name</label>
              <input type="text" class="form-control form-control-lg form-control-solid "
                     formControlName="firstName" id="user-name">
              <div class="m-0 p-0" *ngIf="!validateFirstName()">
                <small class="text-danger">first name is required</small>
              </div>
            </div>
            <div class="col-xl-6">
              <label class="col-form-label fw-bold fs-6" for="last-name">last Name</label>
              <input type="text" class="form-control form-control-lg form-control-solid "
                     formControlName="lastName" id="last-name">
            </div>
            <div class="col-xl-6">
              <label class="col-form-label required fw-bold fs-6" for="user-email">Email</label>
              <input type="email" class="form-control form-control-lg form-control-solid "
                     formControlName="email" id="user-email"
                     [ngStyle]="{'background-color' : userToEdit ? '#E4E6EF' :'none'  }">
              <div class="m-0 p-0">
                <small class="text-danger" *ngIf="emailHasError('required')">Email
                  address is required</small>
                <small class="text-danger" *ngIf="emailHasError('pattern') && !emailHasError('restrictedDomain')">Email
                  address should have a correct form</small>
                <small class="text-danger" *ngIf="emailHasError('restrictedDomain')">
                  The email domain must be <strong>{{ userForm.controls.email.errors?.restrictedDomain?.allowedDomain }}</strong>.
                </small>
              </div>
            </div>
            <div class="col-xl-6">
              <label class="col-form-label  fw-bold fs-6" for="company">Company</label>
              <input type="text" class="form-control form-control-lg form-control-solid "
                     formControlName="company" id="Company">
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="hasPermission([{moduleAccess:ModuleAccess.LICENSE_MANAGEMENT,accessLevel:AccessLevel.READ}])">
        <div class="fw-bold fs-3 rotate collapsible my-5 d-block" data-bs-toggle="collapse" role="button"
              [attr.aria-expanded]="!isCollapsed2"
             aria-controls="kt_modal_update_customer_user_info">Media and licensing
          <span class="ms-2 rotate-180"
                (click)="collapse2.toggle()"
                (keydown.enter)="collapse2.toggle()">
            <i class="ki-duotone ki-down fs-3" [ngClass]="{ 'ki-up': !isCollapsed2 }"></i>
          </span>
        </div>
        <div class="collapse show" #collapse2="ngbCollapse" [(ngbCollapse)]="isCollapsed2">
          <div class="row">
            <label class="col-form-label fw-bold fs-6 required required" for="user-role">Media and licensing</label>
            <div data-kt-buttons="true" class="row">
              <div *ngFor="let license of licenses | slice:0:3" class=" col-xl-4">
                <!--end::Description-->
                <label
                  class="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-6 mb-5 h-80px"
                  [ngClass]="{'active': oui.checked}">
                  <div class="d-flex align-items-center me-2 ">
                    <!--begin::Radio-->
                    <div class="form-check form-check-custom form-check-solid form-check-primary me-6">
                      <input class="form-check-input" type="radio" formControlName="licenseId" name="licenseId" #oui
                             value="{{license.id}}"/>
                    </div>
                    <!--end::Radio-->

                    <!--begin::Info-->
                    <div class="flex-grow-1">
                      <h2 class="badge badge-success ms-2 fs-7 mb-1 d-flex align-items-center fs-3 fw-bold flex-wrap">
                        {{license.name}}
                      </h2>
                    </div>
                    <!--end::Info-->
                  </div>
                </label>
                <!--end::Price-->
              </div>
              <small class="text-danger"
                     *ngIf="userForm.controls.licenseId.errors?.required && userForm.controls.licenseId.touched">
                The choice of license and media is required</small>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="hasPermission([
          {moduleAccess:ModuleAccess.PARTNER_MANAGEMENT,accessLevel:AccessLevel.READ},
          {moduleAccess:ModuleAccess.ADVERTISER_MANAGEMENT,accessLevel:AccessLevel.READ}
       ])">
        <div class="fw-bold fs-3 rotate collapsible my-5 d-block" data-bs-toggle="collapse" role="button"
              [attr.aria-expanded]="!isCollapsed3"
             aria-controls="kt_modal_update_customer_user_info">Partner Information
          <span class="ms-2 rotate-180"
                (click)="collapse3.toggle()"
                (keydown.enter)="collapse3.toggle()">
            <i class="ki-duotone ki-down fs-3" [ngClass]="{ 'ki-up': !isCollapsed3 }"></i>
          </span>
        </div>
        <div class="collapse show" #collapse3="ngbCollapse" [(ngbCollapse)]="isCollapsed3">
          <div class="row">
            <div class="col-xl-6" *ngIf="hasPermission([
          {moduleAccess:ModuleAccess.PARTNER_MANAGEMENT,accessLevel:AccessLevel.READ},
       ]) || hasAllPermission([
          {moduleAccess:ModuleAccess.PARTNER_CONFIGURATION,accessLevel:AccessLevel.READ},
          {moduleAccess:ModuleAccess.PARTNER_CONFIGURATION,accessLevel:AccessLevel.UPDATE},
          {moduleAccess:ModuleAccess.PARTNER_CONFIGURATION,accessLevel:AccessLevel.CREATE},
          {moduleAccess:ModuleAccess.PARTNER_CONFIGURATION,accessLevel:AccessLevel.DELETE}

       ])">
              <label class="col-form-label required fw-bold fs-6" for="user-partner">Partner</label>
              <ng-select [items]="allPartners" class="custom" id="user-partner"
                         bindLabel="name"
                         [multiple]="true"
                         (remove)="removePartner($event.id)"
                         [closeOnSelect]="false"
                         (open)="getPartners()"
                         [loading]="partnersSpinner"
                         [readonly]="!hasPermission([{moduleAccess:ModuleAccess.PARTNER_MANAGEMENT,accessLevel:AccessLevel.UPDATE}])"
                         formControlName="partners">
              </ng-select>
              <div class="m-0 p-0" *ngIf="!validatePartners()">
                <small class="text-danger">User partner is required</small>
              </div>
            </div>
            <div *ngIf="hasPermission([
          {moduleAccess:ModuleAccess.ADVERTISER_MANAGEMENT,accessLevel:AccessLevel.READ}
       ]) && hasDV" class="col-xl-6" [ngClass]="{'disabled':userForm.controls.dvAdvertisers.disabled}">
              <label class="col-form-label  fw-bold fs-6" for="AdDv">Advertisers(Display & video 360)</label>
              <ng-select [items]="advertisersDv" class="custom" id="AdDv"
                         bindLabel="advertiserName"
                         [compareWith]="compareFn"
                         [multiple]="true"
                         [closeOnSelect]="false"
                         [loading]="advertisersDvSpinner"
                         groupBy="partnerName"
                         [readonly]="!hasPermission([{
                             moduleAccess:ModuleAccess.ADVERTISER_MANAGEMENT,
                             accessLevel:AccessLevel.UPDATE
                         }])"
                         (clear)="clearAll('dvAdvertisers')"
                         (change)="isCheckedAllDv();selectionchange($event,'dvAdvertisers')"
                         (open)="onOpenAdvertisersSelect(MediaType.DISPLAY_VIDEO)"
                         formControlName="dvAdvertisers">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items.slice(0,maxElementToShowInDvAdvertisersSelect)">
                    <span class="ng-value-label">{{ item.advertiserName }}</span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                  <div class="ng-value" *ngIf="items.length > maxElementToShowInDvAdvertisersSelect">
                <span class="ng-value-icon right" [matTooltip]="getAllAdvertisersLabel('dvAdvertisers')"
                      (click)="showAllSelected('dvAdvertisers')">
                    {{ items.length - maxElementToShowInDvAdvertisersSelect }} more...
                </span>
                  </div>
                  <div class="ng-value" *ngIf="maxElementToShowInDvAdvertisersSelect!==defaultMaxElementToShowInSelect">
                <span class="ng-value-icon right" (click)="hideSelected('dvAdvertisers')">
                  less
                </span>
                  </div>
                </ng-template>
                <ng-template ng-header-tmp>
                  <label>
                    <input type="checkbox" class="form-check-input" [checked]="isCheckedAllDvAdvertisers"
                           (change)="toggleSelectAllDv($event)">
                    <span class="fw-bold ps-2 fs-6">All
                </span>
                  </label>
                </ng-template>
              </ng-select>
            </div>

            <div *ngIf="hasPermission([
          {moduleAccess:ModuleAccess.ADVERTISER_MANAGEMENT,accessLevel:AccessLevel.READ}
       ]) && hasDSP" class="col-xl-6" [ngClass]="{'disabled':userForm.controls.amzAdvertisers.disabled}">
              <label class="col-form-label  fw-bold fs-6" for="AdDsp">Advertisers(Amazon Dsp)</label>
              <ng-select [items]="advertisersDsp" class="custom" id="AdDsp"
                         bindLabel="advertiserName"
                         [compareWith]="compareFn"
                         [multiple]="true"
                         [loading]="advertisersAmzSpinner"
                         [closeOnSelect]="false"
                         groupBy="partnerName"
                         [readonly]="!hasPermission([{moduleAccess:ModuleAccess.ADVERTISER_MANAGEMENT,accessLevel:AccessLevel.UPDATE}])"
                         (clear)="clearAll('dspAdvertisers')"
                         (change)="isCheckedAllDsp();selectionchange($event,'dspAdvertisers')"
                         (open)="onOpenAdvertisersSelect(MediaType.AMAZON_DSP)"
                         formControlName="amzAdvertisers">

                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items.slice(0,maxElementToShowInDspAdvertisersSelect)">
                    <span class="ng-value-label">{{ item.advertiserName }}</span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                  <div class="ng-value" *ngIf="items.length > maxElementToShowInDspAdvertisersSelect">
                <span class="ng-value-icon right" [matTooltip]="getAllAdvertisersLabel('dspAdvertisers')"
                      (click)="showAllSelected('dspAdvertisers')">
                    {{ items.length - maxElementToShowInDspAdvertisersSelect }} more...
                </span>
                  </div>
                  <div class="ng-value"
                       *ngIf="maxElementToShowInDspAdvertisersSelect!==defaultMaxElementToShowInSelect">
                <span class="ng-value-icon right" (click)="hideSelected('dspAdvertisers')">
                  less
                </span>
                  </div>
                </ng-template>
                <ng-template ng-header-tmp>
                  <label>
                    <input type="checkbox" class="form-check-input" [checked]="isCheckedAllDspAdvertisers"
                           (change)="toggleSelectAllDsp($event)">
                    <span class="fw-bold ps-2 fs-6">All
                </span>
                  </label>
                </ng-template>
              </ng-select>
            </div>
            <div *ngIf="hasPermission([
          {moduleAccess:ModuleAccess.ADVERTISER_MANAGEMENT,accessLevel:AccessLevel.READ}
       ]) && hasFB" class="col-xl-6" [ngClass]="{'disabled':userForm.controls.fbAdsAdvertisers.disabled}">
              <label class="col-form-label  fw-bold fs-6" for="fbAdsAdvertisers">Advertisers(Meta)</label>
              <ng-select [items]="advertisersFbAds" class="custom" id="fbAdsAdvertisers"
                         bindLabel="advertiserName"
                         [compareWith]="compareFn"
                         [multiple]="true"
                         [closeOnSelect]="false"
                         groupBy="partnerName"
                         [readonly]="!hasPermission([{moduleAccess:ModuleAccess.ADVERTISER_MANAGEMENT,accessLevel:AccessLevel.UPDATE}])"
                         (clear)="clearAll('fbAdsAdvertisers')"
                         (change)="isCheckedAllFb();selectionchange($event,'fbAdsAdvertisers')"
                         (open)="onOpenAdvertisersSelect(MediaType.FACEBOOK_ADS)"
                         [loading]="advertisersFbSpinner"
                         formControlName="fbAdsAdvertisers">

                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items.slice(0,maxElementToShowInFbAdvertisersSelect)">
                    <span class="ng-value-label">{{ item.advertiserName }}</span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                  <div class="ng-value" *ngIf="items.length > maxElementToShowInDspAdvertisersSelect">
                <span class="ng-value-icon right" [matTooltip]="getAllAdvertisersLabel('fbAdsAdvertisers')"
                      (click)="showAllSelected('fbAdsAdvertisers')">
                    {{ items.length - maxElementToShowInFbAdvertisersSelect }} more...
                </span>
                  </div>
                  <div class="ng-value" *ngIf="maxElementToShowInFbAdvertisersSelect!==defaultMaxElementToShowInSelect">
                <span class="ng-value-icon right" (click)="hideSelected('fbAdsAdvertisers')">
                  less
                </span>
                  </div>
                </ng-template>
                <ng-template ng-header-tmp>
                  <label>
                    <input type="checkbox" class="form-check-input" [checked]="isCheckedAllFbAdvertisers"
                           (change)="toggleSelectAllFb($event)">
                    <span class="fw-bold ps-2 fs-6">All
                </span>
                  </label>
                </ng-template>
              </ng-select>
            </div>
            <div *ngIf="hasPermission([
          {moduleAccess:ModuleAccess.ADVERTISER_MANAGEMENT,accessLevel:AccessLevel.READ}
       ]) && hasXDR" class="col-xl-6" [ngClass]="{'disabled':userForm.controls.xandrAdvertisers.disabled}">
              <label class="col-form-label  fw-bold fs-6" for="xandrAdvertisers">Advertisers(Xandr)</label>
              <ng-select [items]="advertisersXandr" class="custom" id="xandrAdvertisers"
                         bindLabel="advertiserName"
                         [compareWith]="compareFn"
                         [multiple]="true"
                         [closeOnSelect]="false"
                         groupBy="partnerName"
                         [readonly]="!hasPermission([{moduleAccess:ModuleAccess.ADVERTISER_MANAGEMENT,accessLevel:AccessLevel.UPDATE}])"
                         (clear)="clearAll('xandrAdvertisers')"
                         (change)="isCheckedAllXandr();selectionchange($event,'xandrAdvertisers')"
                         (open)="onOpenAdvertisersSelect(MediaType.XANDR)"
                         [loading]="advertisersXandrSpinner"
                         formControlName="xandrAdvertisers">

                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items.slice(0,maxElementToShowInXandrAdvertisersSelect)">
                    <span class="ng-value-label">{{ item.advertiserName }}</span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                  <div class="ng-value" *ngIf="items.length > maxElementToShowInXandrAdvertisersSelect">
                <span class="ng-value-icon right" [matTooltip]="getAllAdvertisersLabel('xandrAdvertisers')"
                      (click)="showAllSelected('xandrAdvertisers')">
                    {{ items.length - maxElementToShowInXandrAdvertisersSelect }} more...
                </span>
                  </div>
                  <div class="ng-value"
                       *ngIf="maxElementToShowInXandrAdvertisersSelect!==defaultMaxElementToShowInSelect">
                <span class="ng-value-icon right" (click)="hideSelected('xandrAdvertisers')">
                  less
                </span>
                  </div>
                </ng-template>
                <ng-template ng-header-tmp>
                  <label>
                    <input type="checkbox" class="form-check-input" [checked]="isCheckedAllXandrAdvertisers"
                           (change)="toggleSelectAllXandr($event)">
                    <span class="fw-bold ps-2 fs-6">All
                </span>
                  </label>
                </ng-template>
              </ng-select>
            </div>
            <div *ngIf="hasPermission([
          {moduleAccess:ModuleAccess.ADVERTISER_MANAGEMENT,accessLevel:AccessLevel.READ}
       ]) && hasTKTK" class="col-xl-6" [ngClass]="{'disabled':userForm.controls.tiktokAdvertisers.disabled}">
              <label class="col-form-label  fw-bold fs-6" for="tiktokAdvertisers">Advertisers(TikTok)</label>
              <ng-select [items]="advertisersTiktok" class="custom" id="tiktokAdvertisers"
                         bindLabel="advertiserName"
                         [compareWith]="compareFn"
                         [multiple]="true"
                         [closeOnSelect]="false"
                         groupBy="partnerName"
                         [readonly]="!hasPermission([{moduleAccess:ModuleAccess.ADVERTISER_MANAGEMENT,accessLevel:AccessLevel.UPDATE}])"
                         (clear)="clearAll('tiktokAdvertisers')"
                         (change)="isCheckedAllTikTok();selectionchange($event,'tiktokAdvertisers')"
                         (open)="onOpenAdvertisersSelect(MediaType.TIKTOK)"
                         [loading]="advertisersTikTokSpinner"
                         formControlName="tiktokAdvertisers">

                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items.slice(0,maxElementToShowInTikTokAdvertisersSelect)">
                    <span class="ng-value-label">{{ item.advertiserName }}</span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                  <div class="ng-value" *ngIf="items.length > maxElementToShowInTikTokAdvertisersSelect">
                <span class="ng-value-icon right" [matTooltip]="getAllAdvertisersLabel('tiktokAdvertisers')"
                      (click)="showAllSelected('tiktokAdvertisers')">
                    {{ items.length - maxElementToShowInTikTokAdvertisersSelect }} more...
                </span>
                  </div>
                  <div class="ng-value"
                       *ngIf="maxElementToShowInTikTokAdvertisersSelect!==defaultMaxElementToShowInSelect">
                <span class="ng-value-icon right" (click)="hideSelected('tiktokAdvertisers')">
                  less
                </span>
                  </div>
                </ng-template>
                <ng-template ng-header-tmp>
                  <label>
                    <input type="checkbox" class="form-check-input" [checked]="isCheckedAllTikTokAdvertisers"
                           (change)="toggleSelectAllTikTok($event)">
                    <span class="fw-bold ps-2 fs-6">All
                </span>
                  </label>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="hasPermission([
          {moduleAccess:ModuleAccess.ROLE_MANAGEMENT,accessLevel:AccessLevel.READ}
       ])">
        <div class="fw-bold fs-3 rotate collapsible my-5 d-block" data-bs-toggle="collapse" role="button"
              [attr.aria-expanded]="!isCollapsed4"
             aria-controls="kt_modal_update_customer_user_info">Permissions
          <span class="ms-2 rotate-180"
                (click)="collapse4.toggle()"
                (keydown.enter)="collapse4.toggle()">
            <i class="ki-duotone ki-down fs-3" [ngClass]="{ 'ki-up': !isCollapsed4 }"></i>
          </span>
        </div>
        <div class="collapse show" #collapse4="ngbCollapse" [(ngbCollapse)]="isCollapsed4">
          <div class="row mt-0 p-0">
            <div class="col-xl-6">
              <label class="col-form-label fw-bold fs-6 required" for="user-role">Role</label>
              <ng-select [items]="allRoles" class="custom" id="user-role"
                         bindLabel="name"
                         [multiple]="false"
                         [closeOnSelect]="true"
                         (open)="getRoles()"
                         [loading]="rolesSpinner"
                         [compareWith]="ngSelectCompareWith"
                         formControlName="roles">
              </ng-select>
              <div class="m-0 p-0" *ngIf="!validateRoles()">
                <small class="text-danger">User role is required</small>
              </div>
            </div>
            <div class="col-xl-6">
              <label class="col-form-label fw-bold fs-6" for="user-status">Status</label>
              <select class="form-select form-select-solid form-select-lg " formControlName="status"
                      id="user-status">
                <option value="ACTIVATED">Activated</option>
                <option value="BLOCKED">Blocked</option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="card-footer mt-5">
        <div class="row  p-0 pb-5 float-end">
          <div class="col">
            <button class="btn btn-secondary" (click)="resetAndCloseForm()">
              Cancel
            </button>
          </div>
          <div class="col">
            <button class="saveOrEditUser btn btn-primary" type="submit" style="width: 80px;">
              {{SaveBtnLabel}}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
