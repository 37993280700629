import {UserRoles} from "../../../shared";

export class Role {
  id: number;
  name: UserRoles;
  description: string;
  createdDate?: Date | string;
  updatedDate?: Date;

}
